$bubble-background: $black;
$bubble-arrow: $black;
$bubble-text-color: $white;
$bubble-border-radius: 4px;
$bubble-border-color: transparent;
$bubble-box-shadow-color: $black;
$bubble-box-shadow: 0 4px 8px transparentize($black, .88);
$bubble-box-shadow-ie: $bubble-box-shadow-color;
$bubble-min-width: auto;
$bubble-max-width: 20rem;
$bubble-width: auto;
$bubble-padding-x: d2($space);
$bubble-padding-y: d6($space);
$bubble-text-align: center;
$bubble-font-size: $font-size-small;

$bubble-drop-background: $gray5;
$bubble-drop-arrow: $gray5;
$bubble-drop-text-color: $gray2;
$bubble-drop-border-radius: 0;
$bubble-drop-border-color: $gray4;
$bubble-drop-box-shadow: none;
$bubble-drop-min-width: 3rem;
$bubble-drop-max-width: 20rem;
$bubble-drop-width: 15rem;
$bubble-drop-padding-x: d2($space);
$bubble-drop-padding-y: d2($space);
$bubble-drop-text-align: left;

$bubble-overlay: transparentize($black, 0.5);

@import 'node_modules/ns-bubble/src/scss/component';

.ns-bubble.ns-bubble--add-tne {
  .trigger i.ns-icon-info {
    transform: translateY(2px);
    -webkit-transform: translateY(2px);
  }
  .bubble {
    padding: $space;
    background-color: $white;
    color: $gray1;
    -webkit-filter: drop-shadow(0px 0 1px rgba(70,70,70,1));
    filter        : drop-shadow(0px 0 1px rgba(70,70,70,1));
    -ms-filter    : "progid:DXImageTransform.Microsoft.Dropshadow(OffX=0, OffY=2, Color='#444')";
    filter        : "progid:DXImageTransform.Microsoft.Dropshadow(OffX=0, OffY=2, Color='#444')";
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    i.ns-icon-close {
      position: absolute;
      top: d6($space);
      right: d4($space);
      margin-left: 0;
      padding: d3($space);
      color: $gray2;
      font-size: d1-2($space);
      cursor: pointer;
    }
    i.ns-icon-close:hover {
      color: $black;
    }
    img {
      width: 270px;
    }
  }
  &.ns-bubble[flow^="bottom"] .bubble:before,
  &.ns-bubble--dropdown[flow^="bottom"] .bubble:before {
    border-bottom-color: $white;
  }
}
