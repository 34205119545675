.tag {
  @include button($white, $gray5, $button-font-color-secondary, $button-font-color-secondary,
  $button-border-color-secondary, $button-border-color-secondary-hover);

  @include inline-flex;
  @include middle;
  @include center;
  @include fontsize(theta);
  padding: 0 d2($space) 0 d3($space);
  height: $space;
  border-radius: 12px;

  &:active,
  &:focus {
    background-color: $gray4;
    border-color: $gray3;
  }
  &:focus {
    box-shadow: 0px 0px 0px 3px rgba(198, 208, 210, 0.4);
  }

  i {
    font-size: 12px;
    color: $gray2;
  }

  i ~ span {
    padding-left: d6($space);
  }
}

.border-tag {
  position: relative;
  background-color: var(--color);
  height: 24px;
  width: 6px;
  min-width: 6px;
  max-width: 6px;
}
