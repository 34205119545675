@mixin privacy-policy {
  counter-reset: privacy;
  h6 {
    color: $black;
    margin-bottom: d1-2($space);
    font-weight: $semibold;
    counter-increment: privacy;
    &:before {
      content: counter(privacy) ". ";
    }
  }

  ul {
    counter-reset: privacy-list;
    list-style: none;
    li {
      h6 {
        color: $gray1;
        counter-increment: privacy-list;
        &:before {
          content: counter(privacy-list, lower-alpha) ") ";
        }
      }
    }
  }

  p + p {
    padding-top: d1-2($space);
  }
}
