@keyframes slide-services {
  0% {
    transform: translateX(100%);
    -webkit-transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
    -webkit-transform: translateX(-100%);
  }
}

@keyframes slide-services-popup {
  0% {
    transform: translateX(75%);
    -webkit-transform: translateX(75%);
  }
  100% {
    transform: translateX(-100%);
    -webkit-transform: translateX(-100%);
  }
}

.service-content {
  position: relative;
  width: 100%;
  overflow: hidden;
  margin-left: d4($space);
  &:before,
  &:after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    width: d2($space);
    height: 100%;
    z-index: +1;
  }
  &:before {
    left: -1px;
    background: -moz-linear-gradient(left, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 50%, rgba(255,255,255,0) 100%);
    background: -webkit-gradient(left top, right top, color-stop(0%, rgba(255,255,255,1)), color-stop(50%, rgba(255,255,255,1)), color-stop(100%, rgba(255,255,255,0)));
    background: -webkit-linear-gradient(left, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 50%, rgba(255,255,255,0) 100%);
    background: -o-linear-gradient(left, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 50%, rgba(255,255,255,0) 100%);
    background: -ms-linear-gradient(left, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 50%, rgba(255,255,255,0) 100%);
    background: linear-gradient(to right, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 50%, rgba(255,255,255,0) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#ffffff', GradientType=1 );
  }
  &:after {
    right: -1px;
    background: -moz-linear-gradient(left, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 50%, rgba(255,255,255,1) 100%);
    background: -webkit-gradient(left top, right top, color-stop(0%, rgba(255,255,255,0)), color-stop(50%, rgba(255,255,255,1)), color-stop(100%, rgba(255,255,255,1)));
    background: -webkit-linear-gradient(left, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 50%, rgba(255,255,255,1) 100%);
    background: -o-linear-gradient(left, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 50%, rgba(255,255,255,1) 100%);
    background: -ms-linear-gradient(left, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 50%, rgba(255,255,255,1) 100%);
    background: linear-gradient(to right, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 50%, rgba(255,255,255,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#ffffff', GradientType=1 );
  }
}

.service-list {
  @include flex-middle;
  min-width: max-content;
}

.leaflet-popup-content__body--item .service-list {
  animation: slide-services-popup 12s linear infinite;
  -webkit-animation: slide-services-popup 12s linear infinite;
  // animation-direction: alternate;
  // -webkit-animation-direction: alternate;
}

.stops-show-list__stop .service-list {
  animation: slide-services 10s linear infinite;
  -webkit-animation: slide-services 10s linear infinite;
  // animation-direction: alternate;
  // -webkit-animation-direction: alternate;
}

.service-list__item {
  @include flex-middle;
  margin-left: d4($space);
  min-width: max-content;
  i {
    font-size: d1-2($space);
  }
  i.ns-icon-sparkles {
    color: $info;
  }
}

.service-list__item__text {
  padding-left: d6($space);
  white-space: nowrap;
  @include fontsize(theta);
}
