$sidepanel-lane-icon-size: x1-2($space);
$sidepanel-lane-size: d6($space);
//----------------------------- Planner ----------------------------------//
.sidepanel-map.sidepanel-map--planner {

  .sidepanel-map__header {
    padding-bottom: 0;
  }

  .sidepanel-map__content {
    .sidepanel-map--planner__content {
      height: 100%;

      img {
        width: x6($space);
        max-width: x6($space);
      }
    }

    .sidepanel-map--planner__form {
      @include flex-middle;
      justify-content: center;
      position: relative;
      background-color: $gray5;
      border-radius: 8px;
      padding: d1-2($space) d1-2($space) $space + d6($space);

      .sidepanel-map--planner__form--destination-icons {
        display: none;
      }

      .ns-icon-exchange {
        cursor: pointer;
        color: $gray2;
        &:hover {
          color: $gray1;
        }
      }

      &:after {
        content: '';
        position: absolute;
        bottom: -#{d2($space)};
        width: 0;
        height: 0;
        border: 0 solid transparent;
        border-left-width: d1-2($space);
        border-right-width: d1-2($space);
        border-top: d1-2($space) solid $gray5;
        left: calc(50% - #{d1-2($space)});
      }

      .ns-field__label label {
        color: $black;
      }

      .ns-input,
      .ns-input input:disabled {
        background-color: $white;
      }

      input::-webkit-input-placeholder,
      input:disabled {
        opacity: 1;
      }
    }

    .ns-input.ns-input-group--append .ns-input-group__append i.ns-icon-edit {
      font-size: 1rem;
    }

    .planner-separator {
      padding-top: $space;
      margin-top: $space;
      border-top: 1px solid $gray4;
    }

    .planner-dot {
      @include flex-column;
      @include flex-ends;
      min-height: 28px;
      height: 28px;
    }

    .planner-direction {
      margin-top: 1rem;
    }

    // Rutas disponibles
    .sidepanel-map--planner__items {
      margin-top: d1-2($space) + d2($space);
      padding-bottom: x3($space);

      .sidepanel-map--planner__item {
        @include flex-column;
        justify-content: space-between;
        @include flex-middle;
        position: relative;
        min-height: 76px;
        height: 76px;
        padding: d4($space) d2($space) d3($space) d2($space);
        margin-bottom: d3($space);
        border: 1px solid $gray4;
        border-radius: 8px;
        overflow: hidden;
        cursor: pointer;

        &:after {
          @include absolute(unset, x1-2($space), 0, 0);
          content: '';
          width: $space;
          box-shadow: -8px 0px 6px 2px $white;
          z-index: +1;
          background-color: $white;
        }

        & > div:first-child {
          @include flex-middle;
          justify-content: space-between;
          flex-direction: row;
          width: 100%;
          position: relative;
          z-index: +1;
          min-height: $space;
        }

        .sidepanel-map--planner__item--stops,
        .sidepanel-map--planner__item--stop {
          @include flex-middle;
          .vb-content > div:first-child {
            padding-bottom: 0 !important;
          }
        }

        .sidepanel-map--planner__item--stops {
          min-height: 56px;
          height: 56px;
          overflow-x: scroll;
          overflow-y: hidden;
          width: 100%;
          max-width: 100%;
          transform: translateX(-2px);

          &.animate {
            animation: scrolling-sides 3s 2s linear infinite;
          }

          &.stop-animate {
            animation: stop-scrolling-sides .75s ease-in;
          }

          &.no-animate {
            cursor: pointer;
          }

          &.animate,
          &.stop-animate {
            cursor: grabbing;
          }

          @media screen and (max-width: $md) {
            &.animate:hover,
            &.animate:focus {
              animation: unset;
            }
          }
        }

        .sidepanel-map--planner__item--stop {
          &:not(:last-of-type):after {
            content: $ns-icon-angle-right;
            font-family: 'ns-icon';
            padding: 0 d6($space) 0 0;
            font-size: $space;
            color: $gray4;
          }

          &:last-of-type {
            padding-right: d2($space) + d4($space);
          }

          .ns-icon-walker {
            color: $gray2;
          }

          .ns-icon-walker + span {
            padding: 0 d6($space);
          }
        }

        .sidepanel-map--planner__item--lane {
          @include flex-middle;


          --lane-color: transparent;

          background-color: var(--lane-color);
          color: $white;
          max-width: x3($space);
          height: $space;
          padding: 0 d3($space);
          margin-left: d4($space);
          font-size: $font-size-small;
          font-weight: lighter;
          border-radius: 12px;
          white-space: nowrap;
        }
      }
    }

    // Ruta seleccionada
    .sidepanel-map--planner__route-icon {
      @include flex-middle;
      justify-content: center;
      width: $sidepanel-lane-icon-size;
      height: $sidepanel-lane-icon-size;
      min-width: $sidepanel-lane-icon-size;
      min-height: $sidepanel-lane-icon-size;
      margin: d6($space) d3($space) 0 0;
      border-radius: 50%;
      border: 1px solid $gray5;
      box-shadow: 0px 0px 4px 0px transparentize($black, .85);
    }

    .sidepanel-map--planner__route__step {
      .sidepanel-map--planner__route-lane {
        min-width: $sidepanel-lane-size;
        width: $sidepanel-lane-size;
        min-height: $space + d4($space);
        height: 100%;
        background-color: var(--lane-color, #{$black});
        border-radius: 12px;
        margin: 4px 0 2px calc((#{$sidepanel-lane-icon-size} / 2) - #{$sidepanel-lane-size / 2});
      }

      &:last-of-type .sidepanel-map--planner__route-icon {
        margin-top: 0;
      }
    }

    .ns-icon-warning {
      padding-top: 2px;
      font-size: 1rem;
    }
  }
}

@media screen and (min-width: $md) {
  .sidepanel-map.sidepanel-map--planner {
    .sidepanel-map__content {
      .sidepanel-map--planner__content {
        @include flex-column;
        height: initial;
        // padding: 0 d2($space) $space;
        .sidepanel-map--planner__form {
          padding: x1-2($space) 0;

          .ns-field .ns-field__label {
            display: none;
          }

          .sidepanel-map--planner__form--destination-icons {
            @include flex-column;
            align-items: center;
            color: $gray4;
            padding: d4($space) d2($space) d2($space) d2($space);

            .ns-icon-circle {
              font-size: 3px;
            }
          }
        }

        .sidepanel-map--planner__items {
          padding-bottom: 0;
          .sidepanel-map--planner__item {
            padding: d4($space) d1-2($space);
            min-height: 56px;
            height: 56px;
            flex-direction: row;

            &:after {
              @include absolute(unset, 0, 0, 0);
              width: d2($space);
            }


            & > div:first-child {
              flex-direction: column;
              align-items: flex-start;
              width: auto;
              min-width: x4($space);
              &:before {
                @include absolute(unset, 0, 0, 0);
                content: '';
                width: d2($space);
                box-shadow: 8px 0px 6px 2px $white;
                z-index: +1;
                background-color: $white;
              }
            }
            .sidepanel-map--planner__item--stops {
              min-height: auto;
              height: auto;
              overflow: hidden;
              padding-left: d2($space);
            }
          }
        }
      }
    }
  }
}

//----------------------------- Iconos en mapa -------------------------------//
.step-marker {

  &, & > i {
    width: $space !important;
    height: $space !important;
  }

  & > i {
    @include flex-middle;
    justify-content: center;
    font-size: 1rem;
    position: relative;
    transform: translate(-#{d2($space)}, -#{d2($space)});
    border-radius: 50%;
    color: $white;
    background-color: $black;
  }

  &.step-map-marker, &.step-map-marker i {
    width: x1-2($space) !important;
    height: x1-2($space) !important;
  }

  &.step-map-marker i {
    font-size: $space;
  }
}
