@import 'node_modules/ns-leaflet/src/index.scss';

.component-ns-leaflet {
  height: calc(70vh - #{d2($space)} - #{d2($space)});
  overflow: hidden;
  border-radius: 8px;
}

.component-ns-leaflet,
.map-container {
  -webkit-overflow-scrolling: touch;
}

.leaflet-pane,
.leaflet-pane *,
.leaflet-top.leaflet-left,
.leaflet-control,
.leaflet-right,
.gm-style-cc {
  z-index: auto !important;
}

.leaflet-control.leaflet-control-attribution * {
  position: relative !important;
  top: unset !important;
  right: unset !important;
  bottom: unset !important;
  left: unset !important;
  z-index: 0 !important;
}

.leaflet-control.leaflet-control-attribution > div {
  @include flex;
}

.leaflet-control.leaflet-control-attribution .gmnoprint:not(.gm-style-cc) > div > div:nth-of-type(2) span {
  display: inline-block;
  -webkit-transform: translateY(-14px);
  transform: translateY(-14px);
}
.leaflet-control.leaflet-control-attribution .gmnoprint.gm-style-cc > div:nth-of-type(2) a {
  display: inline-block;
  -webkit-transform: translateY(-14px);
  transform: translateY(-14px);
}
.leaflet-control.leaflet-control-attribution .gm-style-cc:not(.gmnoprint) > div:nth-of-type(2) a {
  display: inline-block;
  -webkit-transform: translateY(-14px);
  transform: translateY(-14px);
}

.leaflet-left {
  left: auto;
  right: 0;
  .leaflet-control {
    margin: d3($space) d3($space) 0 0;
  }
}

.leaflet-incidence {
  z-index: +1 !important;
}

.leaflet-bus {
  z-index: +2 !important;
}

.leaflet-userPosition,
.leaflet-nearPosition {
  position: fixed;
  z-index: +3 !important;
}

.leaflet-popup {
  z-index: +4 !important;
}

.leaflet-userPosition {
  animation: 2s leaflet-tick infinite;
}

// Solo aplica en las imágenes de paraderos stop-mini.png
.leaflet-container .leaflet-marker-pane img.stop-mini {
  width: 24px;
  height: 24px;
}

// Solo aplica en las imágenes de paraderos stop-mini.png cuando está seleccionado
.leaflet-container .leaflet-marker-pane img.stop-mini.selected {
  width: 30px;
  height: 30px;
}

.leaflet-bottom.leaflet-right {
  bottom: d5($space);
  left: 0;
  right: 0;
  width: 100%;
  padding-right: d2($space);
}

.leaflet-bottom.leaflet-left {
  left: 0;
  bottom: d1-2($space);
  z-index: 0;
}

.leaflet-marker-icon.selected {
  z-index: +1 !important;
}

.leaflet-control-zoom-in,
.leaflet-control-zoom-out {
  background-color: $white !important;
  width: x1-2($space) !important;
  border-radius: 0 !important;
  color: $gray2 !important;
  font-family: $font-family !important;
  font-weight: $regular !important;
}

.leaflet-touch .leaflet-bar {
  display: none;
  border: 0;
  box-shadow: 0px 8px 10px -3px transparentize($black, .98), 0px 2px 4px 0px transparentize($black, .9);

  @media screen and (min-width: $md) {
    display: block;
  }
}

//---------------------------- Google info ----------------------------//
.leaflet-google-mutant .gm-style {
  .gmnoprint, .gmnoscreen, .gm-style-cc {
     bottom: unset !important;
     top: 0;
     pointer-events: all;
  }

  iframe + div:not(.gmnoprint) {
    top: unset;
    pointer-events: all;
    left: d3($space) !important;
    bottom: calc(#{$sidepanel-map-height-xs} + #{d6($space)}) !important;

    @media screen and (min-width: $md) {
      bottom: 27px !important;
    }
  }
}

//----------------------------- Popups ---------------------------------//
.leaflet-popup-content-wrapper,
.leaflet-popup-tip {
  box-shadow: 0px 10px 20px -5px transparentize($black, .92), 0px 4px 8px 0px transparentize($black, .96);
}

.leaflet-popup-content {
  margin: 0;
  width: auto !important;
  font-family: $font-family;
  font-size: $font-size;
}

.leaflet-popup-content__header {
  @include flex-middle;
  padding: d3($space);
  font-size: $font-size;

  span:first-of-type {
    line-height: normal;
  }

  span + span {
    white-space: pre;
    &:before {
      content: '|';
      padding: 0 d4($space);
      font-size: $font-size;
      color: $gray2;
    }
  }
}

.leaflet-popup-content__body {
  &:not(:empty) {
    padding: d2($space);
    border-top: 1px solid $gray4;
  }
}

.leaflet-popup-content__body--item {
  @include flex-middle;

  span:first-child {
    min-width: x2-5($space);
  }

  & + .leaflet-popup-content__body--item {
    padding-top: d4($space);
  }
}

.leaflet-route-capsule {
  --color: red;
  @include flex-middle;
  font-size: $font-size-small;
  padding-right: d4($space);
  padding-bottom: d4($space);

  .leaflet-route-capsule__lane,
  .leaflet-route-capsule__name {
    @include flex-middle;
    justify-content: center;
    height: $space;
  }

  .leaflet-route-capsule__lane {
    background-color: var(--color);
    color: $white;
    min-width: $space + d3($space);
    border-radius: 12px 0 0 12px;
    padding: 0 d3($space);
  }

  .leaflet-route-capsule__lane--extension {
    @extend .text-ellipsis;
    padding-left: d6($space);
    max-width: 2.75rem;

    &:empty {
      display: none;
    }
  }

  .leaflet-route-capsule__name {
    color: $gray2;
    padding: 0 d3($space);
    border: 1px solid var(--color);
    border-left: none;
    border-radius: 0 12px 12px 0;
  }
}

.leaflet-popup.planner-popup {
  .leaflet-popup-close-button {
    display: none;
  }

  .leaflet-popup-content {
    padding: d2($space) $space d2($space) d2($space);
    min-width: auto;
    width: auto !important;

    .ns-icon-walker {
      color: $gray2 !important;
    }

    span {
      @include fontsize(eta);
      padding-left: d3($space);
      white-space: nowrap;
    }
  }
}

//----------------------------- Position Bus ---------------------------------//

.leaflet-position-bus {
  display: inline-block;
  position: absolute;
  background: $white;
  border-radius: 50%;
  border: 2px solid $gray4;
  z-index: +1 !important;
  margin-left: -18px !important;
  margin-top: -18px !important;
  top: 0;
  left: 0;
  width: 36px !important;
  max-width: 36px;
  height: 36px !important;
  max-height: 36px;

  &:after {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    right: 0;
    width: 14px;
    font-family: ns-icon !important;
    font-size: 6px;
    color: $black;
  }

  i.ns-icon-bus {
    @include inline-flex;
    @include center;
    @include middle;
    width: 32px;
    height: 32px;
    position: relative;
    color: $black;
  }

  //---- Direction Bus

  &.north:after {
    content: $ns-icon-caret-up;
    transform: translate(10px, -11px);
    -webkit-transform: translate(10px, -10px);
  }

  &.northwest:after {
    content: $ns-icon-caret-up;
    transform: translate(-7px, -5px) rotate(-45deg);
    -webkit-transform: translate(-7px, -5px) rotate(-45deg);
  }

  &.northeast:after {
    content: $ns-icon-caret-up;
    transform: translate(26px, -5px) rotate(44deg);
    -webkit-transform: translate(26px, -5px) rotate(44deg);
  }

  &.south:after {
    content: $ns-icon-caret-up;
    transform: translate(9px, 34px) rotate(181deg);
    -webkit-transform: translate(9px, 34px) rotate(181deg);
  }

  &.southwest:after {
    content: $ns-icon-caret-up;
    transform: translate(-7px, 28px) rotate(223deg);
    -webkit-transform: translate(-7px, 28px) rotate(223deg);
  }

  &.southeast:after {
    content: $ns-icon-caret-up;
    transform: translate(25px, 28px) rotate(137deg);
    -webkit-transform: translate(25px, 28px) rotate(137deg);
  }

  &.west:after {
    content: $ns-icon-caret-up;
    transform: translate(-13px, 12px) rotate(-90deg);
    -webkit-transform: translate(-13px, 12px) rotate(-90deg);
  }

  &.east:after {
    content: $ns-icon-caret-up;
    transform: translate(31px, 12px) rotate(90deg);
    -webkit-transform: translate(31px, 12px) rotate(90deg);
  }

  //---- Occupancy

  &.high-occupancy {
    background-color: $high-occupancy-bg;
    border-color: $high-occupancy-border;
  }

  &.half-occupancy {
    background-color: $half-occupancy-bg;
    border-color: $half-occupancy-border;
  }

  &.low-occupancy {
    background-color: $low-occupancy-bg;
    border-color: $low-occupancy-border;
  }

  //---- Services
  .services {
    position: absolute;
    top: 4px;
    left: 45px;
    @include flex-middle;
    div + div {
      margin-left: 3px;
    }
  }

  &.accessible .accessible-icon,
  &.sanitized .sanitized-icon {
    background: $white;
    border: 1px solid $gray2;
    border-radius: 2px;
    width: $space;
    min-width: $space;
    max-width: $space;
    height: $space;
  }

  &.accessible {
    .accessible-icon {
      @include flex-middle;
      @include flex-center;
      &:after {
        font-family: ns-icon !important;
        content: $ns-icon-accessible;
        font-size: d1-2($space);
        color: $accesible;
      }
    }
  }

  &.sanitized {
    .sanitized-icon {
      @include flex-middle;
      @include flex-center;
      &:after {
        font-family: ns-icon !important;
        content: $ns-icon-sparkles;
        font-size: d1-2($space);
        color: $info;
      }
    }
  }
}


//----------------------------- Responsive -----------------------------//

@media (min-width: $sm) {
  .leaflet-bottom.leaflet-left {
    bottom: d3($space);
  }
}

@include media(md) {
  .component-ns-leaflet {
    height: calc(700px - #{d2($space)} - #{d2($space)});
  }
}

//----------------------------- Nuevo popup -----------------------------//

.leaflet-zindex {
  z-index: 0!important;
}

.pop-up-lines {
  .stops-show-list {
    .stops-show-list__item {
      .stops-show-list__line {
        border-radius: 0px!important;
      }
      margin-bottom: 0;
      &:last-child {
        .stops-show-list__stop{
          border-bottom: 0px solid $gray4!important;
        }
        .stops-show-list__line {
          border-radius: 0px 0 0 4px!important;
        }
      }
      .stops-show-list__stop{
        & > div:first-of-type {
          padding: 0;
          width: d2($space);
          min-width: calc(#{$space} + #{d4($space)});
          max-width: d2($space);
        }
      }
    }
  }
}