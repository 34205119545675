$input-box-shadow: inset 0 2px 3px 0px $info-light;
$input-box-shadow-focus: inset 0px 2px 3px 0px $info-light,
                         0px 0px 0px 3px $info-light;

@import 'node_modules/ns-input/src/scss/component';

.ns-input {
  .ns-input__inner {
    font-size: $font-size;
  }

  &.ns-input-group--prepend {
    position: relative;

    .ns-input__inner {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }

    .ns-input-group__prepend {
      @include flex-middle;
      position: absolute;
      top: 1px;
      bottom: 1px;
      left: 1px;
      right: x2($space);
      width: x2($space);
      background-color: transparent;
      border: none;
      z-index: +1;
      pointer-events: none;

      & + input {
        padding-left: x2($space);
      }
    }
  }

  &.ns-input-group--append {
    position: relative;
    .ns-input-group__append {
      @include flex-middle;
      justify-content: center;
      position: absolute;
      right: 1px;
      top: 2px;
      bottom: 2px;
      width: x1-2($space);
      padding: 0 d4($space);
      border: none;

      i {
        color: $gray3;
        cursor: pointer;

        &:hover {
          color: $gray2;
        }
      }
    }
    .ns-input__inner {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      padding-right: x2($space);
    }

    .ns-input-group__append {
      background-color: $white;
    }
  }

  &.ns-input--small .ns-input__inner {
    font-size: $font-size-x-small;
    padding: $field-small-padding-x;
  }
}

.ns-input,
.ns-textarea {
  .ns-textarea__inner {
    padding: d3($space) d1-2($space);
    font-size: $font-size;
  }

  .ns-input__inner,
  .ns-textarea__inner {
    &::-webkit-input-placeholder {
      color: $gray3 !important;
      opacity: 1;
    }

    &::-moz-placeholder {  /* Firefox 19+ */
      color: $gray3 !important;
      opacity: 1;
    }

    &:-ms-input-placeholder {
      color: $gray3 !important;
      opacity: 1;
    }

    &::placeholder {
      color: $gray3 !important;
      opacity: 1;
    }
  }
}

.ns-input,
.ns-textarea {
  box-shadow: $input-box-shadow;

  .ns-input__inner:focus,
  .ns-input__inner:active,
  .ns-textarea__inner:focus,
  .ns-textarea__inner:active {
    border-color: $info-light;
  }

  .ns-input__inner:hover {
    border-color: $info;
  }

  .ns-input__inner:focus,
  .ns-textarea__inner:focus {
    border-color: $info;
    box-shadow: $input-box-shadow-focus;
  }

  input, textarea {
    &::placeholder {
      color: $gray1 !important;
      opacity: 1;
    }
  }
}

.ns-input.ns-input-select {
  .ns-input-group__prepend {
    width: x5($space) + d2($space);
    font-size: $font-size-small;
    pointer-events: auto;
    .ns-select {
      width: 100%;
    }

    .ns-select__select {
      background-color: $gray5;
      width: calc(100% - 1px);
      height: calc(#{x1-2($space)} - 2px);
      border: 0;
      border-right: 1px solid $gray4;
      border-radius: 4px 0 0 4px;

      .ns-select__select__search {
        padding-right: $space + d3($space);

        &:focus {
          box-shadow: none;
        }

        @include media(md) {
          padding-bottom: 2px;
        }
      }

      .ns-select__select__arrow:after {
        right: d3($space);
      }
    }

    & + input {
      padding-left: x5($space) + d6($space);

      &::placeholder {
        color: $gray2 !important;
        opacity: 1 !important;
      }
    }
  }
}
