@function fontsize($fontsize) {
  $font-value: type-of($fontsize);

  @if map-has-key($scale-level, $fontsize) {
    $level: map-get($scale-level, $fontsize);
    $size: pow($scale, $level);

    @return #{$size}rem;
  }
}
