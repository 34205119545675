.stops-show-list {
  list-style: none;

  .stops-show-list__item {
    @include flex-ends;
    margin-bottom: d6($space);

    .stops-show-list__line {
      @include flex-middle;
      width: d3($space);
      min-width: d3($space);
      min-height: x2($space);
      border-radius: 4px 0 0 4px;
    }

    .stops-show-list__stop {
      @include flex-middle;
      width: 100%;
      border-bottom: 1px solid $gray4;

      & > div:first-of-type {
        padding: d4($space) d4($space) d4($space) d4($space);
        width: x2-5($space);
        min-width: calc(#{$space} + #{d4($space)});
        max-width: x2-5($space);

        &,
        span {
          @extend .text-ellipsis;
        }
      }

      & > div:last-of-type {
        padding: 0.25rem third($space);
      }
    }

    // Skeleton
    svg {
      width: 100%;
    }
  }
}

.stops-show-list--empty {
  @include flex-column;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: $gray5;
  color: $gray2;
  height: 6.5rem;
  border-radius: 4px;
}

table.stop-correspondences {
  margin: d3($space) 0 x3($space);

  tr td {
    padding: d4($space) 0;
    border: 0;
  }

  .td--ellipsis {
    position: relative;
    width: 100%;

    &:before {
      content: '&nbsp;';
      visibility: hidden;
    }

    span {
      position: absolute;
      top: 4px;
      left: 0;
      right: 0;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .correspondence-text {
    @include fontsize(eta);
    color: $black;
  }

  .ns-button {
    border-radius: 12px;
    height: $space;
    @include fontsize(iota);
  }
}
