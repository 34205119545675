$field-select-icon-arrow: $ns-icon-angle-down;
$field-select-icon-arrow-font-size: $space;
$field-select-icon-arrow-color: $gray2;
$field-select-icon-arrow-color-disabled: $field-border-color-disabled;
$field-select-option-item-bg: $gray5;
$field-select-option-item-bg-disabled: $gray5;
$field-select-option-item-font-color-disabled: $gray4;
$field-select-option-item-selected-icon: $ns-icon-check;
$field-select-option-item-icon-selected-color: $font-color;
$field-select-option-padding: d2($space);
$field-select-option-title-padding: d2($space);
$field-select-option-title-bg: white;
$field-select-option-title-color: $gray1;
$field-select-option-title-border: 1px dotted $gray4;

$field-select-icon-search: $ns-icon-search;
$field-select-pill-bg: $primary;
$field-select-pill-font-color: $white;

@import 'node_modules/ns-select/src/scss/component';

.ns-select.is-disabled {
  .ns-select__select .ns-select__select__search {
    color: $gray1 !important;
    opacity: 1;
  }
}

.ns-select__select {
  transition: all .15s;
  &.is-open {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  .ns-select__select__search {
    left: 0;
    padding-left: d1-2($space);
    padding-right: 2rem;
    &::-moz-placeholder {
      opacity: 1;
    }
  }

  .slot-search-icon {
    width: 2rem;
  }
}

.ns-select__select__options {
  max-height: 170px;
  margin-top: d6($space);
  border-radius: 4px;

  .ns-select__select__item {
    &:hover {
      background-color: transparentize($gray4, .7);
      color: $gray1;
    }

    &.selected {
      color: $black;

      i {
        font-size: 1.2rem;
        display: inline;
      }
    }

    i {
      display: none;
    }
  }
}

.ns-select.ns-select--lg {
  .ns-select__select,
  .ns-select__select__search,
  .slot-search-icon,
  .ns-select__select__arrow,
  .ns-select__select__arrow:after {
    height: x2($space);
    line-height: x2($space);
  }
}

.ns-field.focus .ns-select__select {
  border-color: $info;
  box-shadow: $input-box-shadow-focus;
}

// Menu select on header
.ns-layout__sidebar__map__menu .ns-select {
  margin-top: d2($space);
  width: 13rem;
  z-index: 11;
  .ns-select__select {
    background-color: $gray1;
    border: none;

    input {
      color: $white;

      &::placeholder {
        opacity: 1 !important;
        color: $white !important;
      }
    }

    .ns-select__select__arrow:after {
      color: $gray5;
    }
  }

  .ns-select__select,
  .ns-select__select__search {
    height: 36px;
    border-radius: 4px;
  }

  .ns-select__select__search {
    padding-left: d1-2($space);
  }

  .ns-select__select:not(.is-open) .ns-select__select__arrow:after {
    top: 4px;
  }

  .ns-select__select__arrow:after {
    font-size: 1.7rem;
  }

  .ns-select__select__options {
    margin-top: d5($space);
    border-radius: 4px;
    background-color: $gray1;
    border: none;
    color: $white;
    padding: d4($space) 0 0;

    .ns-select__select__item {
      line-height: 24px;
      height: 31px;

      & > div:hover {
        background-color: $gray2;
      }

      &:hover {
        background-color: transparent;
        color: inherit;
      }

      &.over {
        background: $gray2;
      }
      &.selected {
        color: $white;
      }

      i {
        display: none;
      }
    }

    .ns-select__select__item.selected {
      background-color: $gray2;

      i {
        font-size: 1.2rem;
        display: inline;
      }
    }
  }
}

// Primary select
.ns-select.ns-select--primary {
  box-shadow: 0 5px 4px -1px rgba(51, 51, 51, 0.5);
  border-radius: $field-border-radius;

  .ns-select__select .ns-select__select__search {
    background: $gray5;
    color: $white;
  }
  .ns-select__select .ns-select__select__arrow:after {
    color: $white;
  }
}


.ns-select--mob-passenger {
  .ns-select__select {
    height: x2($space) !important;

    .ns-select__select__search,.ns-select__select__arrow:after {
      height: x2($space) ;
      line-height: x2($space) ;
    }
  }
}

// routes lines selector
.ns-select--lines {

  .ns-select__select.is-open {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .slot-search-options input {
    border-radius: 0;
  }

  .slot-search-options {
    height: 34px;
  }

  .slot-search-options .ns-select__select__options {
    margin-top: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  .slot-search-options__input {
    z-index: +1;
  }
}

// select document type
.ns-select--document-type {
  .ns-select__select .ns-select__select__search {
    padding: 0 $space 0 d3($space) !important;
  }
  .ns-select__select .slot-search-icon,
  .ns-select__select .ns-select__select__arrow {
    width: $space;
  }
  .ns-select__select .ns-select__select__arrow:after {
    right: .15rem !important;
  }
}

@media (min-width: $sm) {
  .ns-select.ns-select--mob-passenger {
    .ns-select__select {
      width: 50% !important;
    }

  }
}
@media (max-width: $xs) {
  .ns-select.ns-select--mob-passenger {
    .ns-select__select {
      width: 100% !important;
    }

  }
}