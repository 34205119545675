//-------------------------  Mixins  ------------------------------//

@mixin bus-on-come {
  @for $i from 1 through 3 {
    i:nth-child(#{$i}) {
      animation: bus-path-in-#{$i} 1.5s ease-in infinite;
    }
  }
}

@mixin bus-coming {
  @for $i from 1 through 4 {
    i:nth-child(#{$i}) {
      animation: bus-coming-#{$i} 1.5s ease-in infinite reverse;
    }
  }
}


//---------------------- Class ------------------------------------------//

.bus-on-come {
  @include bus-on-come;
  @include flex-middle;
  width: 24px;
  color: $gray2;

  i {
    font-size: 6px;

    &:nth-child(2) {
      padding: 0 2px;
    }
  }
}

.bus-coming {
  @include bus-coming;
  @include flex-middle;
  width: 24px;

  i {
    font-size: 16px;
    color: $primary;
    margin-left: -10px;

    &:first-child {
      margin-left: -8px;
    }
  }
}

.preview{
  
  width: 100%;
  text-align: center;
  height: 30px;
  line-height: 25px;
  letter-spacing: 8px;
  border: 1px dashed #888;
  border-radius: 0.5em;
  margin-bottom: 1rem;

}
.alturamodal{
  max-height: 360px !important;
}
.contraste{
  background-color: black !important;
  color: white !important;
}
.contraste2{
  background-color: white  !important;
  color: black !important;
}


.modalStyle{
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  border: 1px solid currentColor;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.2,0,0,1);
  color: #8393A3;
  line-height: 1.5rem;
  //text-transform: uppercase;
  font-weight: 700;
  text-align: center;
  font-size: 0.75rem;
  padding: 0.75rem;
  border-radius: 0.75rem;
  //letter-spacing: 1.8px !important;
  background-color: #fff !important;
  width: 100%;
  .row{
    justify-content: center;
  }
}
.modalStyleLeft{
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  border: 1px solid currentColor;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.2,0,0,1);
  color: #8393A3;
  line-height: 1.5rem;
  //text-transform: uppercase;
  font-weight: 700;
  text-align: center;
  font-size: 0.75rem;
  padding: 0.75rem;
  border-radius: 0.75rem 0rem 0rem 0.75rem;
  //letter-spacing: 1.8px !important;
  background-color: #fff !important;
  width: 100%;
  .row{
    justify-content: center;
  }
}
.modalStyleRight{
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  border: 1px solid currentColor;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.2,0,0,1);
  color: #8393A3;
  line-height: 1.5rem;
  //text-transform: uppercase;
  font-weight: 700;
  text-align: center;
  font-size: 0.75rem;
  padding: 0.75rem;
  border-radius: 0rem 0.75rem 0.75rem 0rem;
  //letter-spacing: 1.8px !important;
  background-color: #fff !important;
  width: 100%;
  .row{
    justify-content: center;
  }
  
}
.tamanoli{
  margin: 0.5rem 3rem 0.5rem 3rem !important;
}
.titulomodal{
  margin-left: 3rem;
  font-size: 0.8rem !important;
}
.restablecer{
  margin: 0 !important;
  justify-content: center;
  background-color: #fff !important;
}

.font-size {
  font-size: 1rem;
}

.tamanoicono{
  height: 1.5rem !important;
}
.miga-pan{
  margin-left: 1.5rem;
  margin-right: 1.5rem;
  background-color: white;
  font-size: 0.8rem !important;
  padding-left: 2rem;
  margin-top: 1.5rem;
  height: 2rem;
  padding-top: 0.5rem;
}
.marginaccessibility{
  margin-left: 1rem;
}
.tablacss{
  color:#4d6f8f
}
.colorFondo{
  background-color: #E8EDF3;
}
.submenuusuario{
  color: #4d6f8f ;
}
.contrasteTextovoz{
  justify-content: center;
    display: flex;
    flex-direction: column;
}

.preview{
  width: 100%;
  text-align: center;
  height: 30px;
  line-height: 25px;
  letter-spacing: 8px;
  border: 1px dashed #888;
  border-radius: 0.5em;
  margin-bottom: 1rem;
}