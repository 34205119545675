$modal-size-md: 40%;
$modal-size-lg: 60%;
$modal-size-sm: 22%;
$modal-size-xs: 95%;

$modal-padding: x1-2($space) x2($space) x2($space);

$modal-header-line-height: 1.25;
$modal-transition-duration: 0.3s !default;
$modal-border-radius: 12px;

$modal-mask-background:   rgba($black, 0.6) !default;
$modal-background-header: $white;
$modal-background-body:   $white;
$modal-background-footer: $white;

@import 'node_modules/ns-modal/src/scss/component';

.ns-modal--size-medium .ns-modal__container {
  @media screen and (min-width: $xs) {
    width: 80%;
  }

  @media screen and (min-width: $md) {
    width: $modal-size-md;
  }
}

.ns-modal--size-small .ns-modal__container {
  @media screen and (min-width: $xs) {
    width: 50%;
  }

  @media screen and (min-width: $lg) {
    width: $modal-size-sm;
  }
}

.ns-modal__close-button {
  padding: d3($space) d3($space) 0 0;
  i.ns-icon-close {
    font-size: 1rem;
    color: $gray2;
  }

  &:hover i.ns-icon-close {
    color: $gray3;
  }
}

.ns-modal--privacy {
  .ns-modal__container {
    padding: 0;
  }

  .ns-modal__header {
    padding: $space $space  d2($space);
  }

  .ns-modal--privacy__content {
    @include privacy-policy;
    height: 30rem;
    max-height: 30rem;

    & p,
    & h6 {
      font-size: $font-size-small;
    }
  }

  .ns-modal__close-button .ns-icon-close {
    font-size: 1rem;
    color: $gray2;
  }


  @media screen and (min-width: $sm) {
    .ns-modal__container {
      min-width: 640px;
    }
  }
}

.ns-modal--browsers {
  .ns-modal__container {
    overflow-y: auto;
  }

  .ns-modal__body {
    padding-top: d2($space);
  }

  img {
    width: 96px;
  }

  .ns-modal--browser__content {
    @include flex-middle;
    justify-content: center;
    margin-top: x2($space);
    flex-wrap: wrap;

    div {
      width: 50%;
      &:nth-child(-n + 2) {
        margin-bottom: $space;
      }
    }
  }

  @media screen and (min-width: $xs) {
    .ns-modal--browser__content  {
      flex-wrap: initial;

      div {
        width: auto;
        &:not(:last-child) {
          margin-right: x2($space);
        }
        &:nth-child(-n + 2) {
          margin-bottom: 0;
        }
      }
    }
  }

  @media screen and (min-width: $md) {
    .ns-modal__container {
      width: 60%;
    }
  }
  @media screen and (min-width: $lg) {
    .ns-modal__container {
      width: 40%;
    }
  }
}
