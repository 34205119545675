.content-enter-active, .content-enter-to {
  animation: appear .5s .5s both;
}
.content-leave-to, .content-leave-active /* .fade-leave-active in <2.1.8 */ {
  animation: dissapear .5s;
}
.disappear-leave-to, .disappear-leave-active /* .fade-leave-active in <2.1.8 */ {
  animation: sidepanel-fade-out .5s;
}

.payment-information-enter-active, .payment-information-enter-to {
  animation: enter-top .25s linear;
}

.payment-information-leave-to, .payment-information-leave-active {
  animation: leave-bottom .25s  linear;
}
