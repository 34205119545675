$tabs-padding-x: d3($space);
$tabs-padding-y: 0;
$tabs-background: $white;
$tabs-height: $space;
$tabs-font-color: $font-color;
$tabs-font-color-active: $primary;
$tabs-font-color-hover: $primary;
$tabs-border-active: $primary;
$tabs-border-radius: 3px;
$tabs-box-shadow: none;

@import 'node_modules/ns-tabs/src/scss/component';

.ns-tabs.primary-tabs {
  justify-content: center;
  padding: d4($space);
  width: auto;
  .ns-tabs__item {
    @include fontsize(eta);
    width: auto;
    height: auto;
    padding: 2px $tabs-padding-x;
    color: $gray1;
    border-radius: 4px;
    line-height: normal;
    cursor: pointer;

    & + .ns-tabs__item {
      margin-left: d3($space);
    }

    &.is-active,
    &:not(.is-active):hover {
      background-color: $gray5;
      color: $black;
    }

    &.is-active {
      background-color: $gray4;
      border: 0;
    }
  }
}

.ns-tabs.navigation-tabs {
  flex-flow: column;

  .ns-tabs__item {
    padding: d2($space);
    height: auto;
    border-radius: 8px;

    & * {
      color: $gray2;
    }

    .ns-icon-angle-right {
      display: none;
    }

    &:hover * {
      color: $black;
    }

    &:hover span {
      color: $gray2;
    }

    h6,
    span {
      white-space: pre-line;
      line-height: 1.25;
    }

    &.is-active {
      background-color: transparentize($primary-light, .6);
      border-bottom: none;

      [class^="ns-icon-"],
      h6 {
        color: $primary-dark;
      }

      span {
        color: $black;
      }

      .ns-icon-angle-right {
        display: inline;
      }
    }
  }
}

.ns-tabs.button-tabs {
  border: 1px solid $gray3;
  border-radius: x1-2($space);
  overflow: hidden;

  .ns-tabs__item {
    height: x1-2($space);
    line-height: x1-2($space);
    @include fontsize(eta);

    &.is-active {
      border-bottom: 0;
      color: $black;
      background-color: $gray4;
      font-weight: bold;
      z-index: 0;
    }

    &:not(.is-active):hover {
      background-color: $gray5;
    }
  }

  .ns-tabs__item + .ns-tabs__item {
    border-left: 1px solid $gray3;
  }
}

.ns-layout.mobile {
  .ns-layout__content__compacted .sidepanel-map .ns-tabs.primary-tabs {
    .ns-tabs__item {
      margin-bottom: d1-2($space);
      padding: d3($space) d3($space);
    }
  }
}
