h1,
h2,
h3,
h4,
h5,
.fs-alpha,
.text-title {
  font-family: $font-family-secondary;
  line-height: $line-height-heading;
}

h6 {
  font-family: $font-family-secondary;
  line-height: $line-height;
}

// --------------------- Grosor --------------------- //
.regular {
  font-weight: $regular !important;
}

.medium {
  font-weight: $medium !important;
}

.semibold {
  font-weight: $semibold !important;
}

.bold {
  font-weight: $bold !important;
}

// --------------------- Sizes --------------------- //

.text-small {
  @include fontsize(eta);
}

.text-x-small {
  @include fontsize(theta);
}

.text-xx-small {
  @include fontsize(iota);
}

.text-title {
  font-size: 1.4rem;

  @media screen and (min-width: $xs) {
    @include fontsize(alpha);
  }
}

.text-secondary-font {
  font-family: $font-family-secondary;
}


// font-size variables
$font-size-small: fontsize(eta);
$font-size-x-small: fontsize(theta);
