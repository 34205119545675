$form-error-margin-top: d4($space);
$form-error-border-color: $danger;
$form-error-font-size: 0.8rem;
$form-help-padding: 0;
$form-help-background: transparent;
$form-help-text-color: $gray4;
$form-help-icon-color: $gray4;
$form-help-text-size: 0.8rem;
$form-help-icon-size: $font-size;


//----- Formularios pasarela de pago
.wpwl-form {
  border: 0 !important;
  box-shadow: none !important;
  margin-top: 1rem;

  .wpwl-control, .wpwl-form-card {
    background-image: none;
    border: 1px solid $gray3;
    border-radius: 4px;
  }

  .wpwl-group.wpwl-group-brand {
    @include flex;
    @include middle;
  }

  .wpwl-button-pay {
    margin-top: x1-2($space);
    width: 100%;
  }
}


.redsys-form {
  border: 0 !important;
  box-shadow: none !important;

  .redsys-control {
    background-image: none;
    border: 1px solid $gray3;
    border-radius: 4px;
  }

  .redsys-group.redsys-group-brand {
    @include flex;
    @include middle;
  }

  .redsys-button-pay {
    margin-top: x1-2($space);
    width: 100%;
  }
}