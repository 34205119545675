$breadcrumbs-text-color: $gray2;
$breadcrumbs-text-color-hover: $primary;
$breadcrumbs-text-color-active: $primary;
$breadcrumbs-arrow-separator: $gray4;

$breadcrumbs-bg-triangle: $gray4;
$breadcrumbs-bg-triangle-current: $primary;
$breadcrumbs-triangle-text-color: $gray2;
$breadcrumbs-triangle-text-color-active: $white;
$breadcrumbs-triangle-text-color-hover: $primary;
$breadcrumbs-triangle-separator-color: $white;

$breadcrumbs-steps-bar: $gray4;
$breadcrumbs-steps-bar-active: $gray4;

$breadcrumbs-textinside-bg: $gray4;
$breadcrumbs-textinside-bg-hover: $primary;
$breadcrumbs-textinside-bg-current: $primary;
$breadcrumbs-textinside-text-color: $white;
$breadcrumbs-textinside-text-color-hover: $white;

$breadcrumbs-circle-bg: $gray4;
$breadcrumbs-circle-bg-active: $gray4;
$breadcrumbs-circle-text-color: $white;
$breadcrumbs-circle-text-color-hover: $primary;
$breadcrumbs-circle-box-shadow-active: 0 0 0 5px rgba($primary, .25);
$breadcrumbs-circle-box-shadow-hover: 0 0 0 5px rgba($primary, .25);

//Recharges
$breadcrumbs-recharges-size: x2($space);
$breadcrumbs-recharges-margin-top: $breadcrumbs-recharges-size + d3($space);

@import 'node_modules/ns-breadcrumbs/src/scss/component';


.ns-breadcrumbs__recharges,
.ns-breadcrumbs__fares {
  @include flex-ends;

  &.ns-breadcrumb--multi-steps.ns-breadcrumb--text-bottom {
    li {
      width: 100%;

      &:after {
        top: calc((#{$breadcrumbs-recharges-size} / 2) - 2px);
      }

      &.current {
        .ns-breadcrumbs-item__content {
          color: $black;
          &:before {
            background-color: $gray1;
          }
        }
      }
    }

    .ns-breadcrumbs-item__content {
      padding-top: $breadcrumbs-recharges-margin-top;
      font-size: $font-size-x-small;
      cursor: auto;

      &:hover {
        color: $gray2;
      }

      &:before {
        @include flex-middle;
        justify-content: center;
        width: $breadcrumbs-recharges-size;
        height: $breadcrumbs-recharges-size;
        line-height: normal;
        font-family: $font-family;
        box-shadow: none;
      }
    }
  }
}

.ns-breadcrumbs__fares {
  @include flex;
  @include center;
  width: auto;

  &.ns-breadcrumb--multi-steps.ns-breadcrumb--text-bottom li {
    min-width: 50px;
    max-width: 50px;

    &.is-pointer .ns-breadcrumbs-item__content {
      cursor: pointer;
    }

    &:after {
      left: calc(100% - 30px);
    }
  }
}


@media (min-width: $sm) {
  .ns-breadcrumbs__fares {
    &.ns-breadcrumb--multi-steps.ns-breadcrumb--text-bottom li {
      min-width: 150px;
      max-width: 178px;

      &.is-pointer .ns-breadcrumbs-item__content {
        cursor: pointer;
      }

      &:after {
        left: calc(100% - 65px);
      }
    }
  }
}
