//---------------------------- PROJECT SETTINGS ------------------------------//

$background-color:    $background;
$heading-font-color:  $gray1;
$font-color:          $gray1;
$font-size:             1rem;
$line-height-heading:   1.25;
$line-height-body:       1.5;


// Default space
$space: $line-height * 1rem;


// Footer height
$footer-height: 3rem;


// Border default
$border-color: $gray3;
$border-radius: 8px;


// Links
$link-color:       $info;
$link-color-hover: $info-dark;


// Z-Index
$z-index-1: 10;
$z-index-2: 20;
$z-index-3: 30;
$z-index-4: 40;
$z-index-5: 50;


// Transitions
$transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);


// Grid Column Gap
$gutter: 16px;
