.line__favourite {
  @include inline-flex;
  @include middle;
  @include center;
  padding: 0 d2($space);
  margin: 0;
  min-width: x2($space);
  height: $space;
  background-color: $gray5;
  border-radius: 12px;
  cursor: pointer;

  i {
    font-size: 16px;
    color: $gray3;
  }

  &.is-favourite {
    background-color: $black;
    i {
      color: $white;
    }
    i:before {
      content: $ns-icon-star;
    }
  }
}

@media screen and (min-width: $md) {
  .line__favourite {
    &:not(.is-favourite):hover i {
      color: $black;
      &:before {
        content: $ns-icon-star;
      }
    }

    &.is-favourite {
      background-color: $black;
      i {
        color: $white;
      }
    }
  }
}
