$layout-sidebar-width: 320px;
$layout-sidebar-width-xs: 320px;
$layout-sidebar-width-plegate: 4rem;
$layout-container-width: 100%;

$layout-header-height: x5($space);
$layout-header-height-md: x5($space);
$layout-header-height-xs: x3($space);

$layout-mask-bg: rgba($black, 0.50);
$layout-sidebar-bg: $white;
$layout-sidebar-font-color: $white;
$layout-header-bg: $white;
$layout-header-font-color: $white;
$layout-container-bg: transparent;
$layout-margin-sides: 5%;
$layout-max-width: 1728px;
$layout-compacted-max-width: 1440px;

$layout-sidebar-padding: d2($space) $space d2($space);
$layout-sidebar-padding-xs: d2($space) $space d2($space);
$layout-header-padding: 0 $space;
$layout-header-padding-xs: d4($space) $space;

$layout-header-menu-bg-hover: $secondary;
$layout-header-menu-font-color: $white;
$layout-sidebar-menu-bg-hover: $gray2;
$layout-sidebar-menu-font-color: $white;

$layout-content-padding: d2($space) $layout-margin-sides d2($space);
$layout-content-padding-xs: d2($space) $layout-margin-sides d2($space);

$layout-sidebar-transition-time-open: .25s;
$layout-sidebar-transition-time-close: .2s;
$layout-sidebar-transition-time-type: ease-in-out;
$layout-sidebar-transition: $layout-sidebar-transition-time-open $layout-sidebar-transition-time-type;

$layout-footer-height: 138px;
$layout-footer-height-xs: 335px;
// $layout-footer-min-height: 20vh;

$layout-map-height: x3($space);
$layout-map-height-sm: x3($space);
$layout-map-height-xs: x3($space);
$layout-map-width: x6($space);
$layout-map-width-sm: x5($space);

$sidepanel-map-height-xs: 36px;
$sidepanel-map-height: 48px;

@import 'node_modules/ns-layout-sidebar/src/scss/component';

.ns-layout {
  min-height: unset;
  background-color: $gray5;

  .ns-layout__sidebar:not(.hidden) {
    transform: translateX(0%);
    transition: transform .25s, visibility .25s;
    visibility: visible;
  }

  .ns-layout__sidebar.hidden {
    display: block !important;
    transform: translateX(-100%);
    visibility: hidden;
    transition: transform .25s, visibility .25s;
  }

  .ns-layout__sidebar__info {

    a,
    a:hover {
      color: $gray2;
    }

    a + a {
      margin-top: d3($space);
    }

    i.ns-icon-language {
      margin-right: d3($space);
      @include fontsize(zeta);
    }

    h6 {
      color: $black!important;
      @include fontsize(zeta);
      font-weight: 600!important;
      margin-bottom: d2($space);
    }
  }

  .line-horiz {
    border-bottom: 1px solid $gray4 ;
  }

  .ns-layout__footer__copyrights {
    background-color: $gray5;
    img {
      width: 15px!important;
      margin-bottom: 0!important;
    }
  }

  // Botones de la store
  .ns-layout__sidebar__info,
  .ns-layout__footer__content__download {
    img {
      width: 91px;
      margin-bottom: d2($space);
    }
  }

  .ns-layout__footer__content__download {
    li {
      margin-left: 0;
      list-style: none;
      &:hover {
        opacity: 0.7;
      }
    }

    h6 {
      font-weight: $bold;
      color: $black!important;
    }
  }

  .ns-session__nav--login li {
    i, span {
      color: $gray2;
    }

    &:hover {
      i, span {
        color: $black;
      }
    }
  }

  .ns-layout__content {
    transition: none;
    main:not(.ns-layout__content__map) {
      min-height: calc(100vh - #{$layout-header-height-xs});
    }
  }

  // Menus
  & .ns-layout__sidebar ul,
  &.open .ns-layout__sidebar ul {
    padding: 0;
  }

  &.hover .ns-layout__content,
  &.hover .ns-layout__sidebar,
  .ns-layout__content {
    padding: 0;
  }

  &.hover {
    .ns-layout__sidebar {
      & > div {
        min-height: calc(100vh - #{$layout-header-height-xs});
      }
    }

    &:not(.open) > div:last-of-type {
      pointer-events: none;
    }
  }

  &.hover > div:last-of-type:not(.ns-layout__container) {
    @include absolute;
    display: block;
    background: transparentize($black, 1);
    visibility: hidden;
    transition: background .25s, visibility .25s;

    &.ns-layout__mask {
      background: transparentize($black, .5);
      height: 100%;
      visibility: visible;
      transition: background .25s;
      z-index: +10;
    }
  }
}

.ns-layout.mobile {
  .ns-layout__sidebar {
    min-width: $layout-sidebar-width-xs;
    margin-top: $layout-header-height-xs;
  }
}

.ns-layout__header {
  padding: 0 !important;
  box-shadow: 0px 8px 10px -3px transparentize($black, .98),
              0px 2px 4px 0px transparentize($black, .91);

  z-index: $z-index-3 + 1;

  img {
    width: 85px
  }

  .ns-layout__header--city {
    @include flex-middle;
    background-color: transparentize($primary-light, .6);
    color: $primary-dark;
    margin-left: x1-2($space);
    padding: d4($space) d3($space) d4($space) d1-2($space);
    border-radius: 18px;
    cursor: pointer;

    span {
      @include fontsize(eta);
      font-weight: $semibold;
      font-family: $font-family-secondary;
      padding-right: d3($space);
      white-space: nowrap;
    }

    &:hover {
      background-color: transparentize($primary-light, .8);
    }
  }

  .ns-session__nav--preheader {
    background-color: $primary;
    display: flex;
    justify-content: flex-end;
    padding: d4($space) d2($space) d4($space) d2($space);
    @include fontsize(eta);

    li {
      margin-left: d1-2($space);
      min-height: d5($space);
      span, i {
        color: $white;
        font-weight: $medium;
      }

      &:hover i {
        transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
        opacity: 0.7;
        color: $white;
      }
    }

    .ns-session__nav--login {
      li:hover i {
        color: $black;
        opacity: unset;
      }
    }

    .cursor-default {
      &:hover {
        color: $white !important;
      }
    }

    .line-separator {
      content: '';
      height: 100%;
      width: 1px;
      background-color: $white;
      margin-left: d1-2($space);
    }
  }
}

.ns-layout__header,
.ns-layout__sidebar {
  ul {
    list-style: none;
    li {
      color: $gray1;
      margin: 0;
    }
  }
}

.ns-layout__container {
  .ns-layout__header {
    position: fixed;
    width: 100vw;

    & + .ns-layout__content {
      padding-top: $layout-header-height-xs;
    }
  }
}

.ns-layout__content__map {
  .content-map {
    @include flex;
    margin: d2($space) 0;
    overflow: hidden;
    background-color: $white;
    border-radius: 12px;
    border: 1px solid rgb(205, 217, 228);
    box-shadow: 0 0 20px -5px rgba(45, 55, 57, 0.04),
                0 0 8px 0 rgba(45, 55, 57, 0.08);

    .content-map__sidepanel {
      z-index: +1;
      border-right: 1px solid $gray5;
    }

    .content-map__map {
      position: relative;
      padding: d2($space);
      width: 100%;
    }
  }

  .peephole-map {
    @include flex;
    @include middle;
    @include center;
    width: auto;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    pointer-events: none;

    i {
      display: inline-block;
      -webkit-transform: translate(.075rem, 1px);
      transform: translate(.075rem, 1px);
    }
  }

  .ns-layout__content__map--ubication {
    @include flex-middle;
    justify-content: center;
    width: x2($space);
    height: x2($space);
    background-color: $black;
    color: $white;
    font-size: 1.5rem;
    border: 3px solid $white;
    box-shadow: 0px 20px 25px -5px transparentize($black, .97), 0px 6px 16px 0px transparentize($black, .88);
    border-radius: 50%;
    cursor: pointer;

    &:hover {
      background-color: $gray1;
    }

    .ns-icon-ubication {
      padding-left: 1px;
    }
  }

  .ns-layout__content__map--ubication {
    @include absolute(unset, unset, 1.25rem, calc(#{$sidepanel-map-height-xs + #{d2($space)}}));
  }

  .ns-layout__content__map--near {
    @include absolute(unset, $space + x5($space), 1.25rem, unset);
    @include flex-middle;
    justify-content: center;
    background-color: $white;
    color: $gray2;
    width: x1-2($space);
    height: x1-2($space);
    box-shadow: 0px 8px 10px -3px transparentize($black, .98), 0px 2px 4px 0px transparentize($black, .9);
    cursor: pointer;

    &:hover,
    &.selected {
      color: var(--colorIcon);
    }
  }

  .ns-layout__content__map--services-caption {
    @include absolute(unset, $space + x3($space), 1.25rem, unset);
    @include flex-middle;
    justify-content: center;
    background-color: $white;
    color: $gray2;
    width: x1-2($space);
    height: x1-2($space);
    box-shadow: 0px 8px 10px -3px transparentize($black, .98), 0px 2px 4px 0px transparentize($black, .9);
    cursor: pointer;

    &:hover,
    &.selected {
      color: $gray1;
    }

    .trigger {
      @include flex;
    }

    .bubble {
      padding: d2($space);
      background-color: $white;
      color: $gray1;
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }

    &.ns-bubble[flow^="left"] .bubble:before,
    &.ns-bubble--dropdown[flow^="left"] .bubble:before {
      border-left-color: $white;
    }

    ul {
      li {
        @include flex-middle;
        margin-left: 0;
        img {
          margin-right: d4($space);
          width: 18px;
        }
        i {
          min-width: 18px;
          margin-right: d4($space);
          font-size: d1-2($space);
        }
      }
      li + li {
        margin-top: d4($space);
      }
    }
  }
}

.ns-layout__content__map {
  .ns-layout__content__compacted {
    pointer-events: none;
  }
}

.ns-layout__sidebar__map__menu {
  @include flex-ends;
  background-color: $gray5;

  & > ul {
    @include flex-middle;
    width: 100%;
    height: 100%;
    //height: $layout-map-height-xs;
    list-style: none;
    padding: 0 !important;
    overflow: auto;

    li {
      @include flex-column;
      justify-content: center;
      align-items: center;
      height: 100%;
      margin: 0;
      color: $black;
      cursor: pointer;

      &.active,
      &.active:hover {
        background-color: $white;
      }

      &:hover {
        background-color: $white;
      }

      i {
        font-size: 1.4rem;
        color: $gray3;
      }

      &.active i {
        color: $info;
      }
    }

    & + div {
      h6, span {
       line-height: normal;
     }
    }
  }

  img {
    width: 48px;
    height: 48px;
    vertical-align: middle;
  }
}

// Colores de icono para cada item seleccionado
.ns-layout__sidebar__map__menu ul li.active i {
  color: $primary;
}

.ns-layout__footer {
  display: none;
}

//----------------------------- Responsive -----------------------------//
// Responsive (first-mobile). More than mobile size
// Include tablet and desktop
@media screen and (min-width: $xs) {
  .ns-layout {
    background-color: $gray5;

    &.hover .ns-layout__sidebar {
      position: fixed;
      margin-top: 0;
      overflow-y: hidden;

      &, & > div {
        min-height: calc(100vh - #{$layout-header-height-xs});
      }

      .ns-layout__sidebar__info {
        font-size: $font-size-small;
        &:after {
          border-left-width: $layout-sidebar-width;
        }
      }
    }

    &.hover .ns-layout__mask {
      height: 100%;
      top: 0;
    }
  }

  .ns-layout__header {
    position: fixed;
    top: 0;
    width: 100%;

    img {
      height: x1-2($space);
    }
  }

  .ns-layout__container {
    margin-top: $layout-header-height-xs;

    .ns-layout__header {
      & + .ns-layout__content {
        padding-top: 0;
      }
    }
  }

  .ns-layout__sidebar__map__menu {

    ul {
      //height: $layout-map-height-sm;
      margin: d1-2($space) 0 0 $space;
    }

    ul li {
      // padding: 0 $space;
      min-width: x4($space);
      max-width: x4($space);
      width: x4($space);
      margin: 0 d4($space);
      border-radius: 12px;

      i {
        @include flex-middle;
        height: x1-2($space);
      }

      span {
        padding-top: d6($space);
        font-size: $font-size-x-small;
      }
    }
  }
}

// Responsive. More than laptop size
// Include only desktop
@media screen and (min-width: $md) {
  .ns-layout {
    background-color: $gray5;

    .ns-layout__header {
      height: $layout-header-height-md;
      padding: 0 $space 0 x1-2($space);
    }

    .ns-layout__sidebar.hidden {
      display: none !important;
    }

     &:not(.info) {
       .ns-layout__content__map + .ns-layout__footer {
         background-color: transparent;
       }
     }
  }

  .ns-layout__container {
    margin-top: $layout-header-height-md;
  }

  .ns-layout__content__map {
    .content-map__sidepanel {
      z-index: +1;
    }
    .ns-layout__content__map--ubication {
      @include absolute(unset, unset, $space, x2($space));

      .ns-icon-ubication {
        padding-left: 0;
      }
    }

    .ns-layout__content__map--near {
      @include absolute(unset, $space + x5($space), 1.25rem, unset);
    }
  }

  .ns-layout__content__compacted--home {
    max-width: $layout-compacted-max-width;
    margin: 0 auto;
  }

  .ns-layout__footer {
    display: block;
    width: 100%;
    padding-top: x2($space);
    background-color: transparent;
    pointer-events: none;
    .ns-layout__footer__content {
      @include flex;
      @include column;
      width: 100%;
      background-color: $white;
      color: $gray1;
      border-radius: 36px 36px 0px 0px;
      .ns-layout__footer__items {
        @include flex;
        @include between;
        width: 100%;
        padding: x1-2($space) x1-2($space) $space;
        pointer-events: auto;
      }
      .ns-layout__footer__info {
        max-width: 290px;
      }
      .ns-layout__footer__content__privacy {
        font-size: $font-size-small;

        span:first-of-type:after {
          content: '';
          background-color: $gray2;
          margin: 0 d2($space);
          padding: 1px 1px 0;
          font-size: 10px;
        }
      }
      .ns-layout__footer__content__privacy a {
        color: $gray2;
        @include fontsize(eta);
        &:hover {
          color: $black;
        }
      }
    }
    .footer-logo {
      width: auto;
      height: 3.5rem;
    }
    .ns-layout__footer__footer {
      @include flex;
      @include between;
      @include fontsize(theta);
      padding: d3($space) x1-2($space);
      background-color: $gray5;
      border-top: 1px solid $gray5;
      color: $black;
    }
  }
}

// Alturas específicas para el listado de cada mapa
@media (min-width: $md) {
  .ns-layout__content__map .content-map {
    height: 700px;
    max-height: 700px;
    margin: d1-2($space) $space x2($space);

    .vb-content ~ .vb-dragger {
      right: -4px;
    }
  }

  .ns-layout__content__map .content-map.content-map--show-sidepanel {
    .content-map__map {
      padding-left: 0;
    }
    .component-ns-leaflet {
      border-radius: 0 8px 8px 0;
    }
    .sidepanel-map.is-open {
      border-right: 1px solid $gray4;
    }
    .sidepanel-opener {
      right: -9px;
    }
  }
}

// Fix height for iMac
@include media(lg) {
  .ns-layout__content main #municipality_navbar {
    min-height: calc(100vh - #{$layout-footer-height} - #{x4($space)} - #{d3($space)});
  }
}

//---------------------------- Layout Info -----------------------------------//
// Layout para las vistas que no son mapa
// El menú superior deja de ser fijo

.ns-layout.info {
  background-color: $gray5;

  &.open .ns-layout__content main .ns-layout__sidebar__map__menu {
    z-index: 0;
    transition: z-index 0s;
  }

  .ns-layout__content main {
    padding: 0;

    .ns-layout__sidebar__map__menu {
      position: relative;
      z-index: $z-index-3;
      transition: z-index 2s;
      & + section {
        padding-top: 0;
      }
    }
  }

  .ns-layout__content--info {
    padding: $layout-content-padding-xs;

    .ns-layout__content--info__logo-city {
      min-width: 80px;
      width: 80px;
      max-width: 80px;
      height: 80px;
      max-height: 80px;
    }

    &:not(.recharges) img {
      width: 225px;
      height: 225px;
    }

    &.privacy {
      @include privacy-policy;
    }

    .panel {
      box-shadow: 0px 10px 20px -5px transparentize($black, .96), 0px 4px 8px 0px transparentize($black, .92);
    }
  }

  .ns-layout__sidebar__map__menu {
    position: fixed;
    width: 100vw;
  }

  @media screen and (min-width: $sm) {
    .ns-layout__content main {
      .ns-layout__sidebar__map__menu + section {
        padding-top: 0;
      }
    }
  }

  @media screen and (min-width: $md) {
    .ns-layout__content main {
      min-height: calc(100vh - #{$layout-footer-height} - #{$layout-header-height-md} - 2px);
      .ns-layout__sidebar__map__menu + section {
        margin-top: 0;
      }

      .ns-layout__content--info {
        max-width: $layout-compacted-max-width;
        margin-left: auto;
        margin-right: auto;
        padding: $layout-content-padding;
      }
    }
  }

  @media screen and (min-width: $lg) {
    .ns-layout__content main {
      min-height: calc(100vh - #{$layout-footer-height} - #{$layout-header-height} - 2px);
      .ns-layout__sidebar__map__menu + section {
        padding-top: 0;
      }
    }
  }
}

.table-info {
  background-color: $white;
  border: 1px solid $gray4;
  overflow: hidden;
  border-radius: 8px;
}

.table-info table {
  tr th {
    color: $gray2;
  }
  tr td {
    color: $black;
  }
  tr th,
  tr td {
    padding: d1-2($space) d3($space);
    border: 0;
    @include fontsize(eta);
  }
  tr th:first-of-type,
  tr td:first-of-type {
    padding-left: $space;
  }
  tr th:last-of-type,
  tr td:last-of-type {
    padding-right: $space;
  }
  tbody tr:nth-child(odd) {
    background-color: $gray5;
  }
}

//---------------------------- Mixin Info -----------------------------------//
.ns-layout.mixin-layout {
  background-color: $white;

  .ns-layout__content main {
    section:not(.ns-layout__content__compacted) {
      padding: $layout-content-padding;
    }
  }
}

//---------------------------- Header hidden ---------------------------------//

.ns-layout--no-header {
  .ns-layout__header {
    display: none;
  }
  .ns-layout__container .ns-layout__header + .ns-layout__content {
    padding-top: $space;
  }
}


//------------------------- Main title color ---------------------------------//
.h1-main-title {
  margin: x1-2($space) 0 $space;
  color: $black;
  text-align: center;
  @include fontsize(alpha);

  @include media(md) {
    margin: x2($space) 0 x1-2($space);
  }

  &.bold,
  .bold {
    font-weight: 400 !important;
  }

  .uppercase {
    text-transform: inherit !important;
  }
}

.h3-main-title {
  color: $black;
  text-transform: inherit !important;

  &.bold,
  .bold {
    font-weight: 400 !important;
  }

  .uppercase {
    text-transform: inherit !important;
  }
}

//------------------------- Altura fixed panel ---------------------------------//

@media (max-width: $md) {
  html.device-fixed .sidepanel-map.is-open {
    top: 12rem!important;
  }
}

@media (max-width: $sm) {
  html.device-fixed .sidepanel-map.is-open {
    top: 10rem!important;
  }
}