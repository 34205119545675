//------------------------------ COLORS SETTINGS -----------------------------//

// Brand colors
$primary-dark: hsl(5,86,26);  //#FBD4D0
$primary: hsl(5,86,56);       //#EF402F
$primary-light: hsl(6,84,90); //#7B1409

$color-brand-map: (
  'primary': $primary,
  'primary-light': $primary-light,
  'primary-dark': $primary-dark
) !default;


// Gray scale
$black: hsl(0,0,15); //#262626
$gray1: hsl(0,0,35); //#595959
$gray2: hsl(0,0,50); //#808080
$gray3: hsl(0,0,65); //#A6A6A6
$gray4: hsl(0,0,80); //#CCCCCC
$gray5: hsl(0,0,93); //#EDEDED
$white: hsl(0,0,100);//#FFFFFF
$background: hsl(0,0,95);//#F2F2F2

$color-gray-map: (
  'black': $black,
  'gray1': $gray1,
  'gray2': $gray2,
  'gray3': $gray3,
  'gray4': $gray4,
  'gray5': $gray5,
  'white': $white,
  'background': $background
) !default;

// Servicios de Bus
$low-occupancy-bg: #C7E184;
$low-occupancy-border: #81A428;

$half-occupancy-bg: #F9E4CA;
$half-occupancy-border: #FCAA1F;

$high-occupancy-bg: #FFCCE2;
$high-occupancy-border: #FF026E;

// Notify colors
$info-dark: hsl(208,63,30);  //#1C507D
$info: hsl(208,63,60);       //#599ED9
$info-light: hsl(208,63,90); //#D6E7F6

$error-dark: hsl(6,86,26);   //#7B1409
$error: hsl(5,86,56);        //#EF402F
$error-light: hsl(6,84,90);  //#FBD4D0

$warning-dark: hsl(44,93,37); //#B68707
$warning: hsl(44,93,57);      //#F7C12E
$warning-light: hsl(44,94,87);//#FDECBF

$success-dark: hsl(77,61,20); //#415214
$success: hsl(77,61,53);      //#A7D040
$success-light: hsl(77,61,80);//#D9EBAD

$danger-dark: $error-dark;
$danger: $error;
$danger-light: $error-light;

// Secondary and terciary colors
$secondary: $black;
$terciary: $info;

// Customs
$accesible: #2C4CD8;

$color-notify-map: (
  'info-dark': $info-dark,
  'info': $info,
  'info-light': $info-light,
  'error-dark': $error-dark,
  'error': $error,
  'error-light': $error-light,
  'warning-dark': $warning-dark,
  'warning': $warning,
  'warning-light': $warning-light,
  'accesible': $accesible,
  'success-dark': $success-dark,
  'success': $success,
  'success-light': $success-light,
  'danger-dark': $danger-dark,
  'danger': $danger,
  'danger-light': $danger-light
) !default;


// Color gradient
$gradients-map: (
  'gradient-start': $primary,
  'gradient-end': $secondary
) !default;
