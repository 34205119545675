$vuebar-bg: $gray4;
$vuebar-bg-pulse: $gray3;
$vuebar-bar-height: calc(100% - 18px);

.vb > .vb-dragger {
  z-index: 5;
  width: 12px;
  right: 0;
}

.vb > .vb-dragger > .vb-dragger-styler {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform: rotate3d(0,0,0,0);
  transform: rotate3d(0,0,0,0);
  -webkit-transition:
      background-color 100ms ease-out,
      margin 100ms ease-out,
      height 100ms ease-out;
  transition:
      background-color 100ms ease-out,
      margin 100ms ease-out,
      height 100ms ease-out;
  background-color: transparent;
  margin: 5px 5px 5px 0;
  border-radius: 20px;
  height: $vuebar-bar-height;
  display: block;
}

.vb.vb-scrolling-phantom > .vb-dragger > .vb-dragger-styler {
  background-color: transparent;
}

.vb > .vb-dragger:hover > .vb-dragger-styler {
  height: $vuebar-bar-height;
}

.vb.vb-dragging > .vb-dragger > .vb-dragger-styler {
  background-color: $vuebar-bg;
  height: $vuebar-bar-height;
}

.vb.vb-dragging-phantom > .vb-dragger > .vb-dragger-styler {
  background-color: $vuebar-bg-pulse;
}

@media screen and (max-width: $md) {
  .vb-content {
    width: 100% !important;
  }
}

@media screen and (min-width: $md) {
  .vb:not(.vb-invisible):before {
      content: '';
      position: absolute;
      top: 5px;
      right: 1px;
      bottom: 12px;
      width: 7px;
      background-color: $gray5;
      border-radius: 4px;
    }

  .vb.vb-scrolling-phantom > .vb-dragger > .vb-dragger-styler {
    background-color: $vuebar-bg-pulse;
  }

  .vb > .vb-dragger > .vb-dragger-styler {
    background-color: $vuebar-bg;
  }

  .vb > .vb-dragger:hover > .vb-dragger-styler {
    background-color: $vuebar-bg-pulse;
  }

  //-------- Stops map --------//

  .ns-layout__content__compacted.stops:not(.show) .sidepanel-map.is-open {
    .vb:not(.vb-invisible):before {
      content: '';
      position: absolute;
      top: 5px;
      right: 1px;
      bottom: 12px;
      width: 7px;
      background-color: $gray5;
      border-radius: 4px;
    }
  }

  //------------------- Privacy modal -------------------//
  .ns-modal--privacy {
    .vb:not(.vb-invisible) {
      & > .vb-dragger {
        &:after {
          content: unset;
        }
      }

      &:after {
        content: '';
        position: absolute;
        top: d6($space);
        bottom: d2($space);
        right: 5px;
        width: 7px;
        background-color: $gray3;
        border-radius: 4px;
        opacity: .2;
      }
    }
  }
}
