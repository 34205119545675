// Panel de selección de ciudad
.city-panel {
  margin-bottom: d2($space);
  background-color: $white;
  border-radius: 12px;
  overflow: hidden;
  .city-panel__body {
    @include flex-middle;
    display: none;
    padding: d1-2($space);

    img {
      width: x2($space);
      padding-right: d2($space);
    }

    h6 {
      font-weight: 700;
      color: $black;
    }

    .city-description {
      color: $gray2;
      @include fontsize(eta);
    }
  }

  // Responsive
  .city-panel__body--xs {
    @extend .city-panel__body;
    @include flex;
    cursor: pointer;
  }

  &.highlighted .city-panel__body--xs {
    .city-panel__body--circle img {
      min-width: 80px;
      width: 80px;
      max-width: 80px;
      height: 80px;
      max-height: 80px;
    }
    h6 {
      @include fontsize(delta);
    }
  }

  .ns-button {
    transition: none;
  }

  &.disabled {
    opacity: .9;
    .city-panel__header:empty {
      background-color: $white;
    }
    .city-panel__body {
      .city-panel__body--circle img {
        opacity: .6;
      }
      h6 {
        color: $gray4 !important;
      }

      .ns-button {
        span {
          font-weight: $medium;
        }
      }
    }
  }
}

//----- Responsive
@media screen and (min-width: $xs) {
  .city-panel {
    margin-bottom: $space;
    padding: d2($space) 0;

    .ns-button {
      @include flex;
      padding: 0;
      min-width: x1-2($space);
      width: x1-2($space);
      height: x1-2($space);
      border-radius: 50%;
      span {
        @include flex;
        letter-spacing: 0;
        text-transform: uppercase;
        font-size: $font-size-small;
      }
      i {
        color: $gray4;
      }
    }

    .ns-button:hover {
      background-color: $primary-light;
      border-color: $primary-light;
      i {
        color: $primary;
      }
    }

    &.disabled {
      opacity: 1;
    }

    .city-panel__header {
      img,
      &:empty {
        width: 100%;
      }

      &:empty {
        background-color: $white;
      }
    }

    .city-panel__body {
      @include flex-middle;
      width: 100%;
      padding: 0 $space;
    }

    .city-panel__body--xs {
      display: none;
    }

    .city-panel__body--circle {
      @include flex-center;
      align-items: center;
      position: relative;
      margin-right: d1-2($space);

      img {
        min-width: 40px;
        width: 40px;
        max-width: 40px;
        height: 40px;
        max-height: 40px;
        padding: 0;
      }
    }
  }

  // Panel destacado
  .city-panel.highlighted {
    padding: $space 0;
    .city-panel__body h6 {
      @include fontsize(delta);
    }
    .city-panel__body .city-description {
      font-weight: 600;
    }
    .city-panel__body--circle img {
      min-width: 100px;
      width: 100px;
      max-width: 100px;
      height: 100px;
      max-height: 100px;
    }
    .ns-button {
      background-color: $primary;
      border-color: $primary;
      i {
        color: $white;
      }
    }
    .ns-button:hover {
      background-color: $gray2;
      border-color: $gray2;
    }
  }
}
