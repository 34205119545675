//----------------------------- FLEXBOX MIXIN --------------------------------//

@mixin flex {
  display: flex;
  flex-direction: row;
  width: 100%;
}

@mixin inline-flex {
  display: inline-flex;
  width: auto;
}

@mixin start {
  justify-content: flex-start;
  text-align: start;
}

@mixin center {
  justify-content: center;
  text-align: center;
}

@mixin end {
  justify-content: flex-end;
  text-align: end;
}

@mixin top {
  align-items: flex-start;
}

@mixin middle {
  align-items: center;
}

@mixin bottom {
  align-items: flex-end;
}

@mixin between {
  justify-content: space-between;
}

@mixin around {
  justify-content: space-around;
}

@mixin column {
  flex-direction: column;
}

@mixin column-reverse {
  flex-direction: column-reverse;
}

@mixin flex-row {
  flex-direction: row;
}

@mixin bullseye {
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  text-align: center;
}

@mixin first {
  order: -1;
}

@mixin last {
  order: 1;
}

@mixin flex-wrap {
  @include flex;
  flex-wrap: wrap;
}
