$sidepanel-overlay: transparentize($black, 1);
$sidepanel-position: relative;

$sidepanel-content-padding: $space;
$sidepanel-content-bg: $white;
$sidepanel-content-height: auto;
$sidepanel-content-max-height: 450px;
$sidepanel-content-min-height: 350px;
$sidepanel-content-width: 50vw;
$sidepanel-content-max-width: 475px;
$sidepanel-content-min-width: 300px;
$sidepanel-content-width-xs: 85vw;
$sidepanel-content-border: none;
$sidepanel-content-border-radius: 12px;
$sidepanel-content-shadow: -3px 0 10px 1px rgba(0, 0, 0, .25);
$sidepanel-animation-in: .35s;
$sidepanel-animation-out: .25s;

$sidepanel-header-padding: d1-2($space) d1-2($space) $space $space;
$sidepanel-header-bg: $white;
$sidepanel-header-border-width: 0; //En caso de no desear borde este valor ha de ser siempre 0
$sidepanel-header-border: $sidepanel-header-border-width solid $border-color;

$sidepanel-footer-padding: $sidepanel-content-padding;
$sidepanel-footer-bg: $sidepanel-content-bg;
$sidepanel-footer-border-width: 0; //En caso de no desear borde este valor ha de ser siempre 0
$sidepanel-footer-border: $sidepanel-footer-border-width solid $border-color;
$sidepanel-footer-align: flex-end;

$sidepanel-medium-center-height: 500px;
$sidepanel-medium-center-min-height-xs: 520px;
$sidepanel-medium-center-max-height-xs: 90vh;
$sidepanel-medium-body-height: 360 px;

/*Profile request*/

$sidepanel-medium-center-min-height-xs-profile-request: 660px;

@import 'node_modules/ns-sidepanel/src/scss/component.scss';

.ns-sidepanel {
  z-index: +11;

  .ns-sidepanel__content {
    min-height: $sidepanel-content-min-height;
    max-height: $sidepanel-content-max-height;
    max-width: $sidepanel-content-width-xs;
    width: $sidepanel-content-width-xs;
    z-index: +1;
  }

  .ns-sidepanel__header {
    .ns-sidepanel__header__title {
      padding-top: d3($space);
    }
  }

  .ns-sidepanel__body {
    padding: $sidepanel-content-padding;
    overflow: visible;

    .ns-field + .ns-field {
      margin-top: d6($space);
    }
  }

  .ns-sidepanel__footer {
    padding: 0 $space x2-5($space);
  }

  .ns-sidepanel__header,
  .ns-sidepanel__footer {
    justify-content: space-between;
    align-items: center;
    &:after, 
    &:before {
      content: unset;
    }
  }

  .ns-sidepanel__header__close-btn .ns-icon-close {
    color: $gray2;
    font-size: d1-2($space);
  }

  &.no-content-header {
    .ns-sidepanel__header {
      position: absolute;
      right: 0;
      z-index: +1;
      &:after {
        content: unset;
      }
    }

    .ns-sidepanel__body {
      margin-top: d1-2($space);
    }
  }

  &.medium {
    font-weight: normal !important;
    .ns-sidepanel__content {
      min-height: $sidepanel-medium-center-min-height-xs;
      max-height: $sidepanel-medium-center-max-height-xs;
    }

    .ns-sidepanel__body {
      flex-grow: inherit;
    }
  }
}

.ns-sidepanel__login,
.ns-sidepanel__register {
  .ns-sidepanel__footer {
    & > div {
      @include flex-column;
    }
    .ns-button {
      @include flex-center;
      width: 100%;
    }
  }
}

.ns-sidepanel__login .ns-sidepanel__footer > div {
  flex-direction: column-reverse;
}

.ns-sidepanel__register {
  .ns-sidepanel__content {
    overflow: auto;
  }
  .ns-sidepanel__body {
    max-height: $sidepanel-medium-body-height ;
    padding-top: d2($space);
  }

  .ns-sidepanel__footer {
    padding-bottom: x2($space);
  }
}

.ns-sidepanel--modal {
  .ns-sidepanel__content {
    min-height: 420px;
  }
  .ns-sidepanel__body {
    padding-top: d2($space);
  }
  .ns-sidepanel__footer {
    padding: $space;
  }
  .ns-sidepanel__footer:empty {
    display: none;
  }
}

.ns-sidepanel--disable-media {
  .ns-sidepanel__content {
    min-height: unset;
  }
  .ns-sidepanel__header {
    padding-bottom: 0;
  }
  .ns-sidepanel__body {
    padding-bottom: x1-2($space);
    p {
      color: $black;
    }
  }
}

.ns-sidepanel--mini.ns-sidepanel.medium .ns-sidepanel__content {
 min-height: unset;
 height: unset;
 .ns-sidepanel__body {
   padding-top: 0;
 }
 .ns-sidepanel__footer:empty {
   display: none;
 }
}

.ns-sidepanel--language .ns-radio {
  @include flex-middle;

  img {
    padding: 0 d3($space) 0 d4($space);
    width: 30px;
    height: 20px;
  }
}

.ns-sidepanel--accessibility .ns-radio {
  @include flex-middle;

  img {
    padding: 0 d3($space) 0 d4($space);
    width: 30px;
    height: 20px;
  }
}
.contact-modal {
  &.ns-sidepanel.medium .ns-sidepanel__content {
    min-height: unset;
  }

  .ns-sidepanel__body {
    text-align: left;
  }

  .ns-sidepanel__footer {
    @include flex-middle;
    @include flex-ends;
    padding-bottom: $space;
  }

  .ns-checkbox .ns-checkbox__input.is-checked .ns-checkbox__inner:after {
    top: 1px;
    min-width: 10px;
    min-height: 11px;
  }

  .contact-modal__conditions .ns-field__content {
    @include flex;
    @include start;

    span {
      display: inline-block;
      text-align: left;
    }
  }
}

.ns-sidepanel.center,
.ns-sidepanel.ns-sidepanel--modal.center .ns-sidepanel__content {
  text-align: left;
}

.ns-sidepanel.center {
  z-index: $z-index-4;
}

//------------------------ Sidepanel Open ---------------------------//
.ns-sidepanel--overlay-active {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.card-mobile-special {
  &:first-child {
    padding-top: 0;
  }
}


//------------------------ Media Querys ---------------------------//
@media screen and (min-width: $sm) {
  .ns-sidepanel {
    .ns-sidepanel__content {
      width: $sidepanel-content-width;
      max-width: $sidepanel-content-max-width;
    }

    &.medium {
      .ns-sidepanel__content {
        min-height: $sidepanel-medium-center-height;
        max-height: $sidepanel-medium-center-height;
      }
    }
  }

  .ns-sidepanel__login,
  .ns-sidepanel__register {
    .ns-sidepanel__footer {
      & > div {
        flex-direction: row;
      }
      .ns-button {
        display: block;
        width: auto;
      }
    }
  }

  .ns-sidepanel__register {
    .ns-sidepanel__content {
      overflow: visible;
    }
  }
}

@media screen and (min-width: $md) {
  .ns-sidepanel__register {

    .ns-sidepanel__footer {
      padding-bottom: 0;
    }
  }
}
