.ns-cookies {
  position: fixed;
  bottom: d2($space);
  left: 5%;
  width: calc(100vw - 10%);
  padding: x1-2($space) $space;
  background-color: transparentize($white, .05);
  border: 1px solid $gray5;
  border-radius: 12px;
  z-index: +1;
}

@media screen and (min-width: $xs) {
  .ns-cookies {
    padding: x1-2($space) x5($space);
  }
}
