@media screen and (min-width: $md) {
  .start-md {
    @supports not (text-align: start) {
      text-align: left;
    }
  }
}

@media screen and (max-width: $md) {
  // ------------------------ EDGE SUPPORT ---------------------------//
  @supports (-ms-ime-align:auto) {
    html.device-fixed {
      .sidepanel-map {
        transform: translateY(calc(100vh - #{$layout-header-height-xs} - #{$layout-map-height-xs} - #{x2($space)}));
      }
      .sidepanel-opener {
        cursor: pointer;
      }
    }
  }
}
