.account-media-panel,
.tsc-media-panel {
  .account-media-panel__buttons {
    @include flex;
    @include middle;

    .ns-button {
      width: 100%;
    }
  }
}

.account-media-panel,
.tsc-media-panel {
  .payment-media-panel {
    padding-bottom: 1rem!important;
  }
}
.payment-media-panel {
  @extend .panel;

  h5 {
    font-family: $font-family;
  }

  .ns-icon-angle-down {
    transition: transform .25s;
  }

  &.open-info {
    .ns-icon-angle-down {
      transform: rotate(180deg);
    }
  }

  .alias {
    @include flex-middle;
    i {
      font-size: 1rem;
      color: $gray3;
      cursor: pointer;
    }
    i:hover {
      color: $primary;
    }

    i.ns-icon-save {
      color: $primary;
    }
    i.ns-icon-save:hover {
      color: $primary-dark;
    }

    .ns-input .ns-input__inner {
      padding: 0;
      border-top: 0;
      border-left: 0;
      border-right: 0;
      border-radius: 0;
      height: 28px;
      line-height: 28px;
    }
    .ns-input .ns-input__inner:focus {
      border-top: 0;
      border-left: 0;
      border-right: 0;
      box-shadow: none;
    }
  }

  .travel-pill {
    display: inline-flex;
  }
}
.payment-media-panel__information--pending {
  background-color: $danger-light !important;
  margin-top: $space;
  border-radius: $border-radius;
  span b {
    color: $error;
  }
  i {
    font-size:  fontsize(zeta);
  }
}

.payment-media-panel__information--recharge {
  background-color: $info-light;
  margin-top: $space;
  border-radius: $border-radius;

  & ~ div:not(.pending):nth-child(odd) {
    background-color: $gray5;
  }
}

.payment-media-panel__information--item {
  padding: d3($space) d2($space);
  width: 100%;

  &:first-child ~ div:nth-child(even) {
    background-color: $gray5;
  }

  &.pending {
    @include skeleton;
    background-color: $gray5;
    padding: d1-2($space) d2($space);
    color: $gray2;

    &:first-child {
      & ~ div:nth-child(even) {
        background-color: $white;
      }

      & ~ div:nth-child(odd) {
        background-color: $gray5;
      }
    }

    &:not(:first-child) {
      & ~ div:nth-child(even) {
        background-color: $gray5;
      }

      & ~ div:nth-child(odd) {
        background-color: $white;
      }
    }
  }

  .transaction-payment {
    @include flex-middle;
    position: relative;
    margin-left: d2($space);
    padding-left: d2($space);
    &:before {
      content: '';
      position: absolute;
      top: d6($space);
      left: 0;
      height: d1-2($space);
      border-left: 1px solid $gray4;
    }
    i {
      margin: 0 d4($space);
      font-size: d1-2($space);
      color: $info;
    }
  }
}

ul.panel-list-border {
  li {
    border: 1px solid $gray4;
  }
  li:first-of-type {
    border-radius: $border-radius $border-radius 0 0;
  }
  li:last-of-type {
    border-radius: 0 0 $border-radius $border-radius;
  }
  li:only-child {
    border-radius: $border-radius;
  }
}

.payment-media-panel__information-list {
  ul {
    margin-top: d1-2($space);
    list-style: none;
    li {
      @include flex-ends;
      @include flex-middle;
      margin-left: 0;
      padding: d3($space) d2($space);
    }
    li i {
      margin-right: d1-2($space);
      color: $info;
    }
    li + li {
      border-top: 0;
    }
  }

  #profile-request-modal{
    .link-hover{
      cursor: pointer;
    }
    .ns-field{
      padding: 0 0 0.25rem 0;
    }

    
    input[type=file]{
      width: 100%;
      border: 1px solid #7A9AB8;
      text-align: left;
      padding: 5px;
      border-radius: 5px;
      cursor: pointer;
    }

    input[type=file]:disabled{
      cursor: not-allowed !important;
      background-color: #eee;
    }

    input[type=file]:disabled::-webkit-file-upload-button{
      cursor: not-allowed !important;
      background-color: #eee;
    }

    input[type="file"]::-webkit-file-upload-button {
      background: #fff;
      padding: 0px 10px;
      margin-right:0;
      float: right;
      border: 2px solid #7A9AB8;
      border-radius: 5px;
      cursor: pointer;
    }

    .ns-field__label{
      label {
        font-weight: 600;
        font-size: 0.9rem;
      }
    }
  }

  #profile-request-modal ul.ns-select__select__options{
    max-height: 5rem;
    margin-top: 0.25rem;
    border-radius: 4px;
    li.ns-select__select__item{
      border-radius: unset;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      align-items: center;
      position: relative;
      margin-left: 0;
      height: 34px;
      line-height: 34px;
      flex-direction: row;
      cursor: pointer;
      div.ns-select__select__item--text{
        display: inline-block;
        padding-right: 0.75rem;
        overflow: hidden;
        white-space: nowrap;
        padding: 0 3rem 0 0.75rem;
        width: 100%;
        text-overflow: ellipsis;
      }
    }
    li.ns-select__select__item:nth-child(even){
      background-color: white;
    }
  }

  ul.latest-movements {
    margin-bottom: $space;
    padding-left: $space;
    padding-right: $space;
    li {
      @include flex-column;
      padding: d1-2($space) $space;
      // border: 0;
      .row {
        width: 100%;
      }
      i {
        // margin: 0 d4($space);
        margin: 0;
        font-size: 1rem;
        color: $gray3;
      }
    }
    li:nth-child(even) {
      background-color: $gray5;
    }

    .separator--top {
      margin-top: d2($space);
      padding-top: d2($space);
      border-top: 1px solid $gray4;
    }

    .separator--bottom {
      margin-bottom: d2($space);
      padding-bottom: d2($space);
      border-bottom: 1px solid $gray4;
    }
  }

  ul.panel-list-qr-tne {
    li {
      padding-left: 0;
      padding-right: 0;
    }
    li .row {
      margin-left: 0;
    }
  }

  .panel-list-qr-tne__content {
    @include between;
    width: 100%;
    padding-left: $space;
    padding-right: $space;
  }

  .panel-list-qr-tne__content__icon {
    @include flex;
    @include middle;
    min-width: 12rem;
  }

  .panel-list-qr-tne__content__alias {
    @include flex;
    width: 100%;
    i {
      margin: 0;
    }
  }

  .panel-list-qr-tne__content__profile {
    @include flex;
    @include middle;
    .ns-button {
      padding-left: d3($space);
      padding-right: d3($space);
      i {
        margin-right: 0;
        color: $gray3;
        font-size: 1.24rem;
      }
      &:hover i {
        color: $danger;
      }
    }
  }

  table.panel-list-qr-tne__content__profile__table {
    tbody tr td {
      padding: 0;
      border: 0;
      text-align: center;
    }
    tbody tr td:last-of-type {
      min-width: 37px;
    }
  }

  // Iconos específicos para el tipo de viaje
  .double-icon,
  .double-icon--red-arrow {
    position: relative;
    width: 14px;
    min-width: 14px;
    max-width: 14px;
    height: 14px;
    margin: 0;
    color: $gray2;
    font-family: 'ns-icon' !important;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    vertical-align: baseline;
    display: inline-block;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    &:before {
      position: absolute;
      top: 4px;
      left: 0px;
      content: $ns-icon-wayback;
    }
    &:after {
      position: absolute;
      top: -4px;
      left: 5px;
      content: $ns-icon-wayout;
    }
  }
  i.ns-icon-ticket,
  i.ns-icon-bus {
    font-size: 1rem;
  }
  .double-icon--red-arrow:before {
    color: $danger;
  }

  .transaction-payment {
    @include flex-middle;
    i {
      font-size: 1rem;
    }
  }

  .bus-plate {
    margin-left: d3($space);
    padding-left: d3($space);
    border-left: 1px solid $gray4;
  }
}

#empty-movements-img {
  max-width: 120px;
  max-height: 120px;
}

.alias__title {
  @include flex;
  @include middle;
}

.dashed-splice {
  position: relative;
  &:before {
    @include flex-middle;
    content: '';
    position: absolute;
    top: d2($space);
    right: d2($space);
    left: d2($space);
    border: 1px dashed $gray4;
  }
}

.payment-media-panel__no-data {
  &, .payment-media-panel, .payment-media-panel__information {
    height: 100%;
  }

  .payment-media-panel > .row:last-child {
    height: 100%;
  }

  .payment-media-panel__information {
    @include flex-column;
    justify-content: center;
  }
}

// Table for pre-purchase-fares
.table-wstrack {
  padding-right: $space;
  padding-left: $space;

  table {
    margin-bottom: d3($space);

    tr + tr {
      border-top: 1px solid $gray4;
    }
    tr td {
      border: 0;
      padding-right: 0;
      padding-left: 0;
    }
  }

  .label-tag {
    margin-top: 3px;
    padding-left: $space;
    min-width: 3.5rem;
    color: $gray1;
    text-transform: uppercase;
    @include fontsize(eta);
  }

  h5 {
    font-family: $font-family;
  }
}

#profile_media_content{
  .panel-list-qr-tne__content__icon{
    .aux{
      .text-black{
        span.status{
          font-size: 10px;
          color: #5cd2d6;
        }
      }
    }
  }

  .bg-info-light{
    background-color: #00000000 !important;
  }
}

// -------------------------------- Responsive -------------------------------//

@include media(xs) {
  .payment-media-panel__information-list ul.latest-movements {
    padding-left: d1-2($space);
    padding-right: d1-2($space);
  }
  .payment-media-panel__information-list ul.latest-movements li {
    padding: d1-2($space) d3($space);
  }

  .payment-media-panel__information-list .panel-list-qr-tne__content {
    padding-left: d3($space);
    padding-right: d3($space);
  }

  .payment-media-panel__information-list .panel-list-qr-tne__content__alias {
    @include start;
    .alias,
    .ns-field {
      width: 100%;
    }
  }

  .payment-media-panel__information-list .double-icon,
  .payment-media-panel__information-list .double-icon--red-arrow {
    @include fontsize(theta);
    &:before {
      top: 3px;
      left: -1px;
    }
    &:after {
      top: -2px;
      left: 5px;
    }
  }

  .alias__title {
    width: 100%;
    h6 {
      max-width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .dashed-splice {
    display: none;
  }
}

@media (min-width: $sm) {
  .account-media-panel,
  .tsc-media-panel {
    .account-media-panel__buttons .ns-button {
      width: auto;
    }
  }
  .payment-media-panel__information-list .dashed-splice {
    @include flex;
  }
  .table-wstrack {
    padding-right: 8.25rem;
    padding-left: 5.5rem;
  }
}
