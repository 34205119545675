.travel-pill {
  --bg-pill: white;
  @extend .text-ellipsis;
  @include flex-middle;
  @include inline-flex;
  flex: 0 0 auto;
  background-color: var(--bg-pill);
  color: $white;
  padding: 0 d3($space);
  height: $space;
  max-height: $space;
  line-height: $space;
  font-size: $font-size-small;
  border-radius: 12px;
  padding-top: 1px;
  margin-right: d3($space);
}

.travel-pill i {
  @include fontsize(zeta);
}

.tag-pill {
  @extend .travel-pill;
  border-radius: 4px;
}

.travel-pill-list {
  @include flex;
  @include middle;
  width: 100%;
  padding: d6($space) d4($space);
  background-color: var(--bg-pill);
  color: $white;
  border-radius: 16px;
}

.limited-text {
  padding: 5px;
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
}

.limited-text-left {
  padding-left: 5px;
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
}

.limited-text:focus, .limited-text:hover {
  color:transparent;
  &:after{
    content:attr(id-text);
    overflow: visible;
    text-overflow: inherit;
    background: #fff;
    position: absolute;
    left:auto;
    top:auto;
    width: auto;
    max-width: 20rem;
    border: 1px solid #eaebec;
    padding: 1 .5rem;
    box-shadow: 3px 2px 4px 0 rgba(0,0,0,.28);
    white-space: normal;
    word-wrap: break-word;
    display:block;
    color:black;
    margin-top:-2.5rem;
  }
}
