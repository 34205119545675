// Hamburger Variables -------------------------------------------------------//
$hamburger-size: 2rem;
$hamburger-layer-width: 20px;
$hamburger-layer-width-after: 17px;
$hamburger-layer-width-before: 13px;
$hamburger-layer-height: 3px;
$hamburger-layer-spacing: 3px;
$hamburger-layer-color: $gray2;
$hamburger-layer-border-radius: 1.5px;
$hamburger-hover-opacity: 0.7;
$hamburger-hover-transition-duration: 0.15s;
$hamburger-hover-transition-timing-function: linear;
$hamburger-hover-use-filter: false;
$hamburger-hover-filter: opacity(50%);


// Hamburger Menu -------------------------------------------------------------//
.hamburger {
  display: flex;
  display: -ms-flexbox;
  display: -webkit-flex;
  height: $layout-header-height-xs;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: $hamburger-hover-transition-duration;
  transition-timing-function: $hamburger-hover-transition-timing-function;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible;
  &, & * {
    @include disable-highlight;
  }
  @media screen and (min-width: $xs) {
    height: $hamburger-size;
  }
}

.hamburger-box {
  display: flex;
  display: -ms-flexbox;
  display: -webkit-flex;
  position: relative;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  width: $hamburger-size;
  height: $layout-header-height-xs;

  @media screen and (min-width: $xs) {
    height: $hamburger-size;
  }
}

.hamburger-inner {
  display: block;
  width: $hamburger-layer-width;
  top: 50%;
  margin-top: $hamburger-layer-height / -2;
  &,
  &::before,
  &::after {
    height: $hamburger-layer-height;
    background-color: $hamburger-layer-color;
    border-radius: $hamburger-layer-border-radius;
    position: absolute;
    transition-property: transform;
    transition-duration: 0.15s;
    transition-timing-function: ease;
  }

  &::before,
  &::after {
    content: "";
    display: block;
  }

  &::before {
    width: $hamburger-layer-width-before;
    top: ($hamburger-layer-spacing + $hamburger-layer-height) * -1;
  }

  &::after {
    width: $hamburger-layer-width-after;
    bottom: ($hamburger-layer-spacing + $hamburger-layer-height) * -1;
  }
}

// Hamburger Spin ------------------------------------------------------------//
.hamburger--spin {
  .hamburger-inner {
    transition-duration: 0.3s;
    transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);

    &::before {
      transition: top 0.1s 0.34s ease-in,
      width .5s ease-out,
      opacity 0.1s ease-in;
    }

    &::after {
      transition: bottom 0.1s 0.34s ease-in,
      width .5s ease-out,
      transform 0.3s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    }
  }

  &.is-active {
    .hamburger-inner {
      width: $hamburger-layer-width;
      transform: rotate(225deg);
      transition-delay: 0.14s;
      transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);

      &::before {
        top: 0;
        opacity: 0;
        transition: top 0.1s ease-out,
        opacity 0.1s 0.14s ease-out;
      }

      &::after {
        bottom: 0;
        transform: rotate(-90deg);
        transition: bottom 0.1s ease-out,
        transform 0.3s 0.14s cubic-bezier(0.215, 0.61, 0.355, 1);
      }

      &::before,
      &::after {
        width: $hamburger-layer-width;
      }
    }
  }
}

.hamburger + * {
  margin-left: d3($space);
}

@media screen and (min-width: $xs) {
  // Hamburguer states
  .hamburger + * {
    margin-left: d2($space);
  }
}
