@keyframes appear {
  from { transform: translateX(-100%); }
  to { transform: translateX(0%); }
}

@keyframes dissapear {
  0% { transform: translateX(0%);}
  100% { transform: translateX(-100%); }
}

@keyframes sidepanel-fade-in {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes sidepanel-fade-out {
  from { opacity: 1; }
  to { opacity: 0; }
}

@keyframes leaflet-tick {
  0% { max-width: 60px; width: 60px; margin-left: -30px; margin-top: -30px }
  50% { max-width: 80px; width: 80px; margin-left: -40px; margin-top: -40px }
  100% { max-width: 60px; width: 60px; margin-left: -30px; margin-top: -30px }
}

//- Bus circle animation
@keyframes bus-path-in-1 {
  0% {transform: scale(.8)};
  20% {transform: scale(1.5)};
  40%  {transform: scale(.8)};
}

@keyframes bus-path-in-2 {
  20% {transform: scale(.8)};
  40%  {transform: scale(1.5)};
  60% {transform: scale(.8)};
}

@keyframes bus-path-in-3 {
  40%  {transform: scale(.8)};
  60% {transform: scale(1.5)};
  80%  {transform: scale(.8)};
}


//- Bus animation for coming
@keyframes bus-coming-1 {
  0% { transform: scale(0.9); }
  10% { transform: scale(1.4); }
  75% { transform: scale(1.4); }
}
@keyframes bus-coming-2 {
  0% { transform: scale(0.9); }
  20% { transform: scale(1.4); }
  75% { transform: scale(1.4); }
}
@keyframes bus-coming-3 {
  0% { transform: scale(0.9); }
  30% { transform: scale(1.4); }
  75% { transform: scale(1.4); }
}
@keyframes bus-coming-4 {
  0% { transform: scale(0.9); }
  40% { transform: scale(1.4); }
  75% { transform: scale(1.4); }
}

// Skeleton loading
@keyframes skeleton-loader {
  from {left: -100%;}
  to {left: 150%;}
}

// Payload animation
@keyframes payload-line {
  0% { width: 0px; opacity: 0; }
  33% { width: x1-2($space); opacity: 1; }
  70% { opacity: 1; }
  100% {opacity: 0; }
}

@keyframes payload-line-2 {
  0% { width: 0px; opacity: 0; }
  33% { width: $space; opacity: 1; }
  70% { opacity: 1; }
  100% {opacity: 0; }
}

@keyframes enter-top {
  from { max-height: 0px; overflow: hidden;}
  to { max-height: 40rem; overflow: hidden; }
}

@keyframes leave-bottom {
  from { max-height: 40rem; overflow: hidden;}
  to { max-height: 0px;  overflow: hidden;}
}

@keyframes scrolling-sides {
  20% {margin-left: 0}
  50% {margin-left: -25px;}
  80% {margin-left: 0;}
}

@keyframes stop-scrolling-sides {
  from { margin-left: var(--margin);}
  to { margin-left: 0; }
}
