@font-face {
	font-family: "ns-icon";
	src: url("scss/assets/fonts/ns-icon.eot?0393c8e1bc6f0b1ab2874311a5129eea?#iefix") format("embedded-opentype"),
url("scss/assets/fonts/ns-icon.woff2?0393c8e1bc6f0b1ab2874311a5129eea") format("woff2"),
url("scss/assets/fonts/ns-icon.woff?0393c8e1bc6f0b1ab2874311a5129eea") format("woff"),
url("scss/assets/fonts/ns-icon.ttf?0393c8e1bc6f0b1ab2874311a5129eea") format("truetype"),
url("scss/assets/fonts/ns-icon.svg?0393c8e1bc6f0b1ab2874311a5129eea#ns-icon") format("svg");

  font-weight: normal;
  font-style: normal;
}

[class^="ns-icon-"],
[class*=" ns-icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
	font-family: ns-icon !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  vertical-align: baseline;
  display: inline-block;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ns-icon-accessibility:before {
	content: "\f101";
}
.ns-icon-accessible:before {
	content: "\f102";
}
.ns-icon-android:before {
	content: "\f103";
}
.ns-icon-angle-down:before {
	content: "\f104";
}
.ns-icon-angle-left:before {
	content: "\f105";
}
.ns-icon-angle-right:before {
	content: "\f106";
}
.ns-icon-angle-up:before {
	content: "\f107";
}
.ns-icon-arrow-down:before {
	content: "\f108";
}
.ns-icon-arrow-left:before {
	content: "\f109";
}
.ns-icon-arrow-right:before {
	content: "\f10a";
}
.ns-icon-arrow-up:before {
	content: "\f10b";
}
.ns-icon-bars:before {
	content: "\f10c";
}
.ns-icon-bell:before {
	content: "\f10d";
}
.ns-icon-bus-accesible:before {
	content: "\f10e";
}
.ns-icon-bus:before {
	content: "\f10f";
}
.ns-icon-calendar:before {
	content: "\f110";
}
.ns-icon-car:before {
	content: "\f111";
}
.ns-icon-caret-up:before {
	content: "\f112";
}
.ns-icon-chart:before {
	content: "\f113";
}
.ns-icon-check:before {
	content: "\f114";
}
.ns-icon-circle-o:before {
	content: "\f115";
}
.ns-icon-circle:before {
	content: "\f116";
}
.ns-icon-clipboard:before {
	content: "\f117";
}
.ns-icon-clock:before {
	content: "\f118";
}
.ns-icon-close:before {
	content: "\f119";
}
.ns-icon-cloud:before {
	content: "\f11a";
}
.ns-icon-coins:before {
	content: "\f11b";
}
.ns-icon-collapse-horizontal:before {
	content: "\f11c";
}
.ns-icon-collapse-vertical:before {
	content: "\f11d";
}
.ns-icon-cuenta:before {
	content: "\f11e";
}
.ns-icon-delete:before {
	content: "\f11f";
}
.ns-icon-details:before {
	content: "\f120";
}
.ns-icon-dollar:before {
	content: "\f121";
}
.ns-icon-dots-h:before {
	content: "\f122";
}
.ns-icon-dots-v:before {
	content: "\f123";
}
.ns-icon-download-file:before {
	content: "\f124";
}
.ns-icon-download:before {
	content: "\f125";
}
.ns-icon-edit:before {
	content: "\f126";
}
.ns-icon-email:before {
	content: "\f127";
}
.ns-icon-envelope:before {
	content: "\f128";
}
.ns-icon-euro:before {
	content: "\f129";
}
.ns-icon-exchange:before {
	content: "\f12a";
}
.ns-icon-exclamation:before {
	content: "\f12b";
}
.ns-icon-expand-horizontal:before {
	content: "\f12c";
}
.ns-icon-expand-vertical:before {
	content: "\f12d";
}
.ns-icon-experiencias:before {
	content: "\f12e";
}
.ns-icon-external-link:before {
	content: "\f12f";
}
.ns-icon-eye:before {
	content: "\f130";
}
.ns-icon-facebook:before {
	content: "\f131";
}
.ns-icon-fares:before {
	content: "\f132";
}
.ns-icon-file:before {
	content: "\f133";
}
.ns-icon-filter:before {
	content: "\f134";
}
.ns-icon-folder:before {
	content: "\f135";
}
.ns-icon-help:before {
	content: "\f136";
}
.ns-icon-home:before {
	content: "\f137";
}
.ns-icon-horarios:before {
	content: "\f138";
}
.ns-icon-image:before {
	content: "\f139";
}
.ns-icon-incidence:before {
	content: "\f13a";
}
.ns-icon-info:before {
	content: "\f13b";
}
.ns-icon-inicio:before {
	content: "\f13c";
}
.ns-icon-instagram:before {
	content: "\f13d";
}
.ns-icon-ios:before {
	content: "\f13e";
}
.ns-icon-key:before {
	content: "\f13f";
}
.ns-icon-language:before {
	content: "\f140";
}
.ns-icon-lineas:before {
	content: "\f141";
}
.ns-icon-linkedin-in-square:before {
	content: "\f142";
}
.ns-icon-linkedin:before {
	content: "\f143";
}
.ns-icon-loading:before {
	content: "\f144";
}
.ns-icon-login:before {
	content: "\f145";
}
.ns-icon-logout:before {
	content: "\f146";
}
.ns-icon-mailbox:before {
	content: "\f147";
}
.ns-icon-map-marker:before {
	content: "\f148";
}
.ns-icon-map-ubication:before {
	content: "\f149";
}
.ns-icon-mapa:before {
	content: "\f14a";
}
.ns-icon-megaphone:before {
	content: "\f14b";
}
.ns-icon-menu:before {
	content: "\f14c";
}
.ns-icon-message:before {
	content: "\f14d";
}
.ns-icon-minus:before {
	content: "\f14e";
}
.ns-icon-money:before {
	content: "\f14f";
}
.ns-icon-news:before {
	content: "\f150";
}
.ns-icon-origin:before {
	content: "\f151";
}
.ns-icon-paradas:before {
	content: "\f152";
}
.ns-icon-paradero:before {
	content: "\f153";
}
.ns-icon-password:before {
	content: "\f154";
}
.ns-icon-peephole:before {
	content: "\f155";
}
.ns-icon-phone-outline:before {
	content: "\f156";
}
.ns-icon-phone:before {
	content: "\f157";
}
.ns-icon-pin:before {
	content: "\f158";
}
.ns-icon-planner:before {
	content: "\f159";
}
.ns-icon-plus:before {
	content: "\f15a";
}
.ns-icon-point-of-sale:before {
	content: "\f15b";
}
.ns-icon-point-of-sales:before {
	content: "\f15c";
}
.ns-icon-publicidad:before {
	content: "\f15d";
}
.ns-icon-qr:before {
	content: "\f15e";
}
.ns-icon-question:before {
	content: "\f15f";
}
.ns-icon-recargar:before {
	content: "\f160";
}
.ns-icon-recharge:before {
	content: "\f161";
}
.ns-icon-register:before {
	content: "\f162";
}
.ns-icon-reload:before {
	content: "\f163";
}
.ns-icon-rent:before {
	content: "\f164";
}
.ns-icon-routes:before {
	content: "\f165";
}
.ns-icon-save:before {
	content: "\f166";
}
.ns-icon-schedules:before {
	content: "\f167";
}
.ns-icon-search:before {
	content: "\f168";
}
.ns-icon-settings:before {
	content: "\f169";
}
.ns-icon-share:before {
	content: "\f16a";
}
.ns-icon-ship:before {
	content: "\f16b";
}
.ns-icon-sparkles:before {
	content: "\f16c";
}
.ns-icon-square-o:before {
	content: "\f16d";
}
.ns-icon-square:before {
	content: "\f16e";
}
.ns-icon-star-o:before {
	content: "\f16f";
}
.ns-icon-star:before {
	content: "\f170";
}
.ns-icon-stops:before {
	content: "\f171";
}
.ns-icon-tag:before {
	content: "\f172";
}
.ns-icon-tarifas:before {
	content: "\f173";
}
.ns-icon-temuco:before {
	content: "\f174";
}
.ns-icon-ticket:before {
	content: "\f175";
}
.ns-icon-time-stop:before {
	content: "\f176";
}
.ns-icon-times-circle:before {
	content: "\f177";
}
.ns-icon-tne-accepted:before {
	content: "\f178";
}
.ns-icon-tne-disabled:before {
	content: "\f179";
}
.ns-icon-tne:before {
	content: "\f17a";
}
.ns-icon-train:before {
	content: "\f17b";
}
.ns-icon-triangle-o:before {
	content: "\f17c";
}
.ns-icon-triangle:before {
	content: "\f17d";
}
.ns-icon-twitter:before {
	content: "\f17e";
}
.ns-icon-ubication:before {
	content: "\f17f";
}
.ns-icon-unlink:before {
	content: "\f180";
}
.ns-icon-upload:before {
	content: "\f181";
}
.ns-icon-usd-circle:before {
	content: "\f182";
}
.ns-icon-user:before {
	content: "\f183";
}
.ns-icon-ventas:before {
	content: "\f184";
}
.ns-icon-villarica:before {
	content: "\f185";
}
.ns-icon-walker:before {
	content: "\f186";
}
.ns-icon-wallet:before {
	content: "\f187";
}
.ns-icon-warning:before {
	content: "\f188";
}
.ns-icon-wayback:before {
	content: "\f189";
}
.ns-icon-wayout:before {
	content: "\f18a";
}

$ns-icon-accessibility: "\f101";
$ns-icon-accessible: "\f102";
$ns-icon-android: "\f103";
$ns-icon-angle-down: "\f104";
$ns-icon-angle-left: "\f105";
$ns-icon-angle-right: "\f106";
$ns-icon-angle-up: "\f107";
$ns-icon-arrow-down: "\f108";
$ns-icon-arrow-left: "\f109";
$ns-icon-arrow-right: "\f10a";
$ns-icon-arrow-up: "\f10b";
$ns-icon-bars: "\f10c";
$ns-icon-bell: "\f10d";
$ns-icon-bus-accesible: "\f10e";
$ns-icon-bus: "\f10f";
$ns-icon-calendar: "\f110";
$ns-icon-car: "\f111";
$ns-icon-caret-up: "\f112";
$ns-icon-chart: "\f113";
$ns-icon-check: "\f114";
$ns-icon-circle-o: "\f115";
$ns-icon-circle: "\f116";
$ns-icon-clipboard: "\f117";
$ns-icon-clock: "\f118";
$ns-icon-close: "\f119";
$ns-icon-cloud: "\f11a";
$ns-icon-coins: "\f11b";
$ns-icon-collapse-horizontal: "\f11c";
$ns-icon-collapse-vertical: "\f11d";
$ns-icon-cuenta: "\f11e";
$ns-icon-delete: "\f11f";
$ns-icon-details: "\f120";
$ns-icon-dollar: "\f121";
$ns-icon-dots-h: "\f122";
$ns-icon-dots-v: "\f123";
$ns-icon-download-file: "\f124";
$ns-icon-download: "\f125";
$ns-icon-edit: "\f126";
$ns-icon-email: "\f127";
$ns-icon-envelope: "\f128";
$ns-icon-euro: "\f129";
$ns-icon-exchange: "\f12a";
$ns-icon-exclamation: "\f12b";
$ns-icon-expand-horizontal: "\f12c";
$ns-icon-expand-vertical: "\f12d";
$ns-icon-experiencias: "\f12e";
$ns-icon-external-link: "\f12f";
$ns-icon-eye: "\f130";
$ns-icon-facebook: "\f131";
$ns-icon-fares: "\f132";
$ns-icon-file: "\f133";
$ns-icon-filter: "\f134";
$ns-icon-folder: "\f135";
$ns-icon-help: "\f136";
$ns-icon-home: "\f137";
$ns-icon-horarios: "\f138";
$ns-icon-image: "\f139";
$ns-icon-incidence: "\f13a";
$ns-icon-info: "\f13b";
$ns-icon-inicio: "\f13c";
$ns-icon-instagram: "\f13d";
$ns-icon-ios: "\f13e";
$ns-icon-key: "\f13f";
$ns-icon-language: "\f140";
$ns-icon-lineas: "\f141";
$ns-icon-linkedin-in-square: "\f142";
$ns-icon-linkedin: "\f143";
$ns-icon-loading: "\f144";
$ns-icon-login: "\f145";
$ns-icon-logout: "\f146";
$ns-icon-mailbox: "\f147";
$ns-icon-map-marker: "\f148";
$ns-icon-map-ubication: "\f149";
$ns-icon-mapa: "\f14a";
$ns-icon-megaphone: "\f14b";
$ns-icon-menu: "\f14c";
$ns-icon-message: "\f14d";
$ns-icon-minus: "\f14e";
$ns-icon-money: "\f14f";
$ns-icon-news: "\f150";
$ns-icon-origin: "\f151";
$ns-icon-paradas: "\f152";
$ns-icon-paradero: "\f153";
$ns-icon-password: "\f154";
$ns-icon-peephole: "\f155";
$ns-icon-phone-outline: "\f156";
$ns-icon-phone: "\f157";
$ns-icon-pin: "\f158";
$ns-icon-planner: "\f159";
$ns-icon-plus: "\f15a";
$ns-icon-point-of-sale: "\f15b";
$ns-icon-point-of-sales: "\f15c";
$ns-icon-publicidad: "\f15d";
$ns-icon-qr: "\f15e";
$ns-icon-question: "\f15f";
$ns-icon-recargar: "\f160";
$ns-icon-recharge: "\f161";
$ns-icon-register: "\f162";
$ns-icon-reload: "\f163";
$ns-icon-rent: "\f164";
$ns-icon-routes: "\f165";
$ns-icon-save: "\f166";
$ns-icon-schedules: "\f167";
$ns-icon-search: "\f168";
$ns-icon-settings: "\f169";
$ns-icon-share: "\f16a";
$ns-icon-ship: "\f16b";
$ns-icon-sparkles: "\f16c";
$ns-icon-square-o: "\f16d";
$ns-icon-square: "\f16e";
$ns-icon-star-o: "\f16f";
$ns-icon-star: "\f170";
$ns-icon-stops: "\f171";
$ns-icon-tag: "\f172";
$ns-icon-tarifas: "\f173";
$ns-icon-temuco: "\f174";
$ns-icon-ticket: "\f175";
$ns-icon-time-stop: "\f176";
$ns-icon-times-circle: "\f177";
$ns-icon-tne-accepted: "\f178";
$ns-icon-tne-disabled: "\f179";
$ns-icon-tne: "\f17a";
$ns-icon-train: "\f17b";
$ns-icon-triangle-o: "\f17c";
$ns-icon-triangle: "\f17d";
$ns-icon-twitter: "\f17e";
$ns-icon-ubication: "\f17f";
$ns-icon-unlink: "\f180";
$ns-icon-upload: "\f181";
$ns-icon-usd-circle: "\f182";
$ns-icon-user: "\f183";
$ns-icon-ventas: "\f184";
$ns-icon-villarica: "\f185";
$ns-icon-walker: "\f186";
$ns-icon-wallet: "\f187";
$ns-icon-warning: "\f188";
$ns-icon-wayback: "\f189";
$ns-icon-wayout: "\f18a";
