.skeleton-loading {
  // Stops map
  &.stops-show-list .stops-show-list__item,
  &.routes-show-list .routes-show-list__item {
    @include skeleton;
  }

  &.stops-show-list .stops-show-list__item {
    &:nth-child(2):before {
      animation: skeleton-loader 1.5s .5s ease-in-out infinite;
    }
  }

  &.routes-show-list .routes-show-list__item {
    height: 49px;
  }
}
