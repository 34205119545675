.ns-layout__footer__content__privacy {
  @include flex-middle;
  a {
    @include inline-flex;
    @include middle;
  }
  a:hover {
    color: $primary-light;
  }
  i.ns-icon-language {
    margin-right: d6($space);
    margin-bottom: 2px;
    @include fontsize(zeta);
  }
}
