//----------------------------- BUTTON VARIABLES -----------------------------//
$button-font-color:                         $white;
$button-font-color-secondary:               $gray1;
$button-font-size:                          $font-size;
$button-height:                             x1-2($space);
$button-padding-x:                          d2($space);
$button-border-color:                       transparent;
$button-border-color-hover:                 transparent;
$button-border-color-secondary:             $gray4;
$button-border-color-secondary-hover:       $gray4;
$button-border-radius:            24px;

$button-sm-font-size:          $font-size-x-small;
$button-sm-height:             $space;
$button-sm-padding-x:          d2($space);

$button-lg-font-size:          $font-size-small;
$button-lg-height:             x2($space);
$button-lg-padding-x:          d1-2($space);

$button-transparent-bg:           transparent;
$button-transparent-border-color: rgba($white, 0);

$button-disabled-bg:              $gray5;
$button-disabled-font-color:      $gray3;
$button-disabled-border-color:    $gray4;


//----------------------------- BUTTON COMPONENT -----------------------------//

// Create buttons from button-mixin
//
// @include button(bg-color, bg-color-hover, font-color, font-color-hover, border-color, border-color-hover);

.ns-button {
  @include button(transparent, $primary, $button-font-color, $white,
                  $button-border-color, $button-border-color-hover);
}

.ns-button--primary {
  @include button($primary, tint($primary, 25%), $button-font-color, $button-font-color,
                  $button-border-color, $button-border-color-hover);

  font-family: $font-family-secondary;

  &:active,
  &:focus {
    background-color: $primary-dark;
  }
  &:focus {
    box-shadow: 0 0 3px $primary-dark;
  }

  &.ns-button--disabled {
    &,
    &:hover {
      background-color: $button-disabled-bg !important;
      color: $button-disabled-font-color !important;
      border-color: $button-disabled-border-color !important;
      font-weight: $regular;
      box-shadow: none;
    }
  }
}

.ns-button--secondary {
  @include button($white, $gray5, $button-font-color-secondary, $button-font-color-secondary,
                  $button-border-color-secondary, $button-border-color-secondary-hover);
  font-weight: $medium;
  font-family: $font-family-secondary;

  &:active,
  &:focus {
    background-color: $gray4;
    border-color: $gray3;
  }
  &:focus {
    box-shadow: 0px 0px 0px 3px rgba(198, 208, 210, 0.4);
  }

  &.ns-button--disabled,
  &.ns-button--disabled:hover {
    background-color: $button-disabled-bg !important;
    color: $button-disabled-font-color !important;
    border-color: $button-disabled-border-color !important;
    font-weight: $regular;
    box-shadow: none;
  }
}

.ns-button--primary,
.ns-button--secondary {
  box-shadow: 0px 1px 2px 0px transparentize($black, .9);
}

.ns-button--ghost {
  @include button($button-transparent-bg, $gray5, $gray1, $gray1,
                  $button-transparent-border-color, $button-transparent-border-color);
  font-weight: $medium;

  &:active,
  &:focus {
    background-color: $gray4;
  }
  &:focus {
    box-shadow: 0px 0px 0px 3px rgba(198, 208, 210, 0.4);
  }
}

.ns-button {
  height: $button-height;
  line-height: $button-height;
  padding: 0 $button-padding-x;
  font-size: $font-size-small;

  & + .ns-button {
    margin-left: d2($space);
  }
}

.ns-button--sm {
  height: $button-sm-height;
  line-height: $button-sm-height;
  padding: 0 $button-sm-padding-x;
  font-size: $button-sm-font-size;
}

.ns-button--lg {
  height: $button-lg-height;
  line-height: $button-lg-height;
  padding: 0 $button-lg-padding-x;
  font-size: $button-lg-font-size;
  font-family: $font-family-secondary;
  font-weight: $medium;
  letter-spacing: 0px;

  span {
    letter-spacing: 0;
    text-transform: uppercase;
  }
}

.ns-button--disabled,
.ns-button--disabled:hover,
.ns-button--disabled:active,
.ns-button--disabled:focus {
  background:   $button-disabled-bg !important;
  color:        $button-disabled-font-color !important;
  border-color: $button-disabled-border-color !important;
  cursor:       not-allowed;
}

.ns-button--icon {
  // padding-left: d3($space);

  & > div > span {
    @include flex-middle;
  }
}

.ns-button--icon > div > span,
.ns-button--icon > div,
a.ns-button--icon {
  i {
    font-size: $space;
  }
  i ~ span {
    margin-left: d3($space);
  }
  span ~ i {
    margin-left: d4($space);
  }
}

.ns-button.small-text {
  span {
    @include fontsize(eta);
    letter-spacing: 0;
    text-transform: uppercase;
  }

  div {
    max-height: d1-2($space);
  }
}

.ns-button--left {
  width: 100%;
  & > div {
    @include start;
  }
}

.button-phone {
  display: inline-block;
  background-color: $gray5;
  padding: d3($space) d2($space) d3($space) d2($space);
  border-radius: $border-radius;
  line-height: initial;
  &:hover {
    box-shadow: 0px 2px 2px 0px transparentize($black, .98),
                0px 2px 2px 0px transparentize($black, .91);
  }
  i {
    font-size: 1.2rem;
  }
}

// Responsive ----------------------------------------------------------------//
@include media(xs) {
  a.ns-button {
    @include flex-center;
  }

  .ns-button--icon > div > span,
  .ns-button--icon > div,
  a.ns-button--icon {
    i {
      font-size: 1rem;
    }
  }
}
