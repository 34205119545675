$sidepanel-map-width-md: 600px;

$sidebar-opener-height-xs: 32px;
$sidebar-opener-height: 48px;

$sidepanel-map-height-xs-close: calc(100vh - #{$layout-header-height-xs} - #{$layout-map-height-xs} - #{d2($space)});
$sidepanel-map-height-xs-plegate: calc(100vh - #{$layout-header-height-xs} - #{$layout-map-height-xs} - #{d2($space)});

$sidepanel-opener-width: 16px;


.sidepanel-map {
  position: fixed;
  left: 0;
  right: 0;
  height: 100%;
  -webkit-transform: translateY($sidepanel-map-height-xs-close);
  transform: translateY($sidepanel-map-height-xs-close);
  transition: transform .5s;
  display: flex;
  flex-direction: column-reverse;

  &.is-open {
    -webkit-transform: translateY($sidebar-opener-height-xs);
    transform: translateY($sidebar-opener-height-xs);
    transition: transform .5s;

    .ns-icon-expand-horizontal {
      -webkit-transform: rotate(180deg);
      transform: rotate(180deg);
    }
  }

  .sidepanel-map__container {
    background-color: $white;
    padding: d1-2($space);
    height: 100%;
    pointer-events: all;
    @include flex;
    @include column;

    .sidepanel-map__header {
      @include flex-middle;
      -webkit-flex: 0 0 auto;
      flex: 0 0 auto;
      .sidepanel-map__header__icon-lane {
        @include fontsize(epsilon);
        @include flex-center;
        align-items: center;
        width: x2($space);
        min-width:x2($space);
        height: x2($space);
        min-height: x2($space);
        padding: d3($space);
        margin: 0 d2($space) 0 d3($space);
        border-radius: 8px;

        & + div {
          line-height: normal;
        }
      }
    }

    .sidepanel-map__content {
      -webkit-flex: 1 1 auto;
      -moz-flex: 1 1 auto;
      -ms-flex: 1 1 auto;
      flex: 1 1 auto;
      -webkit-overflow-scrolling: touch;

      & > div,
      .sidepanel-map__content__routes {
        animation: sidepanel-fade-in 1s;
      }

      .sidepanel-map__content__routes {
        padding: d2($space) d2($space) $space;

        .sidepanel-map__content__routes--item {
          @include flex;
          padding-left: $space;

          i {
            color: $gray3;
          }

          .sidepanel-map__content__routes--item-info {
            @include flex;
            width: 100%;
            padding: 0 0 d2($space) d1-2($space);
          }

          .sidepanel-map__content__routes--item-circles {
            @include flex-column;
            align-items: center;
            i {
              color: $gray2;
              font-size: 2px;
              padding-bottom: 2px;
            }
          }

          &.is-selected {
            .ns-icon-paradero {
              color: $primary;
            }

            .sidepanel-map__content__routes--item-info > span {
              font-weight: bold;

              &:hover {
                color: $black;
              }
            }
          }
        }
      }
    }
    .table-schedule {
      background-color: $white;
      border: 1px solid $gray4;
      overflow: hidden;
      border-radius: 8px;
    }
    .table-schedule table {
      tr th,
      tr td {
        border: 0;
        text-align: center;
      }
      tbody tr:nth-child(odd) {
        background-color: $gray5;
      }
      tr th {
        @include fontsize(iota);
        span {
          display: inline-block;
          padding: d2($space) 0 d6($space);
          color: $gray2;
        }
      }
    }
  }
}

.sidepanel-opener {
  @include flex-middle;
  justify-content: center;
  background-color: $white;
  height: $sidebar-opener-height-xs;
  border-radius: 12px 12px 0 0;
  box-shadow: 0 -3px 3px 0 rgba(0, 0, 0, 0.15);
  pointer-events: all;
}

/* Sidepanel Card */
.sidepanel-map__card {
  padding-bottom: x5($space);

  &.no-header {
    .sidepanel-map__card--item {
      &:first-of-type {
        border-top: 1px solid $gray4;
        border-radius: 8px 8px 0 0;
      }
    }
  }

  header {
    position: relative;
    padding: 0 d1-2($space);
    border-radius: 8px 8px 0 0;
    span {
      @include fontsize(eta);
      color: $white;
    }
  }

  .sidepanel-map__card--item {
    position: relative;
    padding: d2($space);
    border-left: 1px solid $gray4;
    border-right: 1px solid $gray4;
    cursor: pointer;

    &.selected {
      background-color: $gray5;

      .line__favourite:not(.is-favourite) {
        border: 1px solid $gray3;
      }
    }

    .card--body {
      @include flex;
      @include middle;
    }

    .card--footer {
      @include flex;
      @include middle;
      @include between;
      padding-top: d3($space);
    }

    .card--footer i.ns-icon-incidence {
      position: relative;
      span {
        position: absolute;
        @include flex;
        @include middle;
        @include center;
        top: 5px;
        left: 0;
        right: 0;
        bottom: 0;
        color: $black;
        font-family: $font-family;
        font-weight: bold;
        @include fontsize(iota);
      }
    }

    .sidepanel-map-card--item__route {
      font-size: $font-size-small;
      color: $black;
    }

    .sidepanel-map-card--item__bus {
      border-radius: 8px;
      background: rgba(var(--color), 0.15);
      color: rgba(var(--color), 1);

      &,
      & + div .ns-icon-details {
        @include flex-center;
        align-items: center;
        width: x1-2($space);
        min-width: x1-2($space);
        height: $space;
      }

      .ns-icon-bus {
        @include fontsize(epsilon);
      }
    }

    .sidepanel-map__card--item__stop {
      color: $black;
      font-weight: $semibold;
      min-width: 3ch;
      text-align: right;
    }

    .sidepanel-map__card--item__stop--route,
    .sidepanel-map__card--item__point-name {
      color: $black;
    }

    & + .sidepanel-map__card--item {
      border-top: 1px solid $gray4;
    }

    &:last-child {
      border-radius: 0 0 8px 8px;
      border-bottom: 1px solid $gray4;
    }

    &:only-child {
      border-top: 1px solid $gray4;
      border-radius: 8px !important;
    }

    .ns-icon-details {
      border-radius: 4px;
      color: $gray2;
      cursor: pointer;
      &:hover {
        color: $gray1;
      }
    }
  }
}

.sidepanel-route-list {
  padding-bottom: x5($space);

  .sidepanel-map__card {
    padding-bottom: d3($space);
  }
}

// Fix for iPhone SE or <370px
@media screen and (max-width: 370px) {
  .sidepanel-route-list .sidepanel-map__card .sidepanel-map__card--item .card--footer {
    @include flex-row;
    @include column;

    .flex:first-child {
      @include top;
      width: 100%;
    }
    .flex:last-child {
      @include end;
      width: 100%;
      margin-top: d3($space);
    }
  }
}

@media screen and (min-width: $sm) {
  .sidepanel-map {
    &.is-open {
      -webkit-transform: translateY($sidebar-opener-height-xs / 2);
      transform: translateY($sidebar-opener-height-xs / 2);
      transition: transform .5s;

      .ns-icon-triangle {
        -webkit-transform: rotate(180deg);
        transform: rotate(180deg);
      }
    }
  }
}

@media screen and (min-width: $md) {
  .sidepanel-map {
    position: relative;
    left: unset;
    right: unset;
    -webkit-transform: translateX(0);
    transform: translateX(0);
    transition: margin-left .25s;
    width: $sidepanel-map-width-md;
    margin-left: -#{$sidepanel-map-width-md};
    pointer-events: none;
    display: block;

    &.is-open {
      -webkit-transform: translateX(0);
      transform: translateX(0);
      transition: margin-left .25s;
      margin-left: 0;

      .sidepanel-opener {
        i {
          -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
        }
      }
    }

    .sidepanel-map__container {
      pointer-events: all;

      .sidepanel-map__header {
        min-height: $sidebar-opener-height;
      }

      .sidepanel-map__content {
        padding-top: 0;

        .sidepanel-map__content__routes {
          padding: d4($space) $space $space d2($space);

          .sidepanel-map__content__routes--item {
            padding-left: $space;
          }
        }
      }
    }

    .sidepanel-map__card {
      padding-bottom: d3($space);
    }

    .sidepanel-map__card--item:hover .sidepanel-map-card--item__route {
      color: $gray2;
    }
  }

  .sidepanel-route-list {
    padding-bottom: x5($space);

    .sidepanel-map__card {
      padding-bottom: d3($space);
    }
  }

  .sidepanel-opener {
    position: absolute;
    top: 24px;
    right: -22px;
    width: $sidepanel-opener-width;
    height: $sidebar-opener-height;
    border-radius: 4px;
    border: 1px solid $gray4;
    box-shadow: unset;
    cursor: pointer;

    i {
      -webkit-transform: rotate(90deg);
      transform: rotate(90deg);
    }
  }

  //-------- PLanner map--------//

  .ns-layout__content__compacted.show .sidepanel-map--planner {
    .sidepanel-map__content.vb-visible .vb-content > div:first-child {
      padding-right: d2($space) !important;
    }
  }

  .ns-layout__content__compacted.header-large {
    .sidepanel-map__header .stop-tabs {
      @include flex-middle;
      justify-content: center;
      min-height: $sidebar-opener-height;
    }

    &:not(.show) {
      .sidepanel-map__container .sidepanel-map__content {
        .vb-dragger:after {
          // top: $sidepanel-map-height + $sidebar-opener-height + 115px !important;
          bottom: 18px !important;
        }
      }
    }
  }
}
