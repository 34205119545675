@mixin skeleton($rotate: -45deg) {
  position: relative;
  overflow: hidden;

  &:before {
    content: '';
    transform: rotate($rotate);
    animation: skeleton-loader 1.5s ease-in-out infinite;
    background-color: transparentize($white, .4);
    position: absolute;
    left: -100%;
    top: 0;
    bottom: 0;
    width: 24px;
    box-shadow: 0 0 45px 100px transparentize($white, .4);
  }
}
