@media screen and (orientation: landscape) and (max-height: 450px) {
  html {
    font-size: 87.5%;
  }
  .ns-layout.hover .ns-layout__sidebar {
    min-height: calc(#{$vh} * 100 - #{$layout-header-height-xs});
    max-height: calc(#{$vh} * 100 - #{$layout-header-height-xs});
    overflow-y: auto;
  }
  .ns-layout.mobile {
    background-size: 100%;
    background-position: center bottom;
  }
  .ns-layout__sidebar__cookies {
    padding: x1-2($space) $space;
  }

  .ns-layout__content__map {
    .ns-layout__content__map--near {
      @include absolute(unset, unset, x3($space) + d1-2($space), calc(#{$sidepanel-map-height-xs + #{d2($space)}}));
    }

    .ns-layout__content__map--ubication {
      @include absolute(unset, unset, calc(d3($space) + 2px), calc(#{$sidepanel-map-height-xs + #{d2($space)}}));
    }
  }

  .ns-sidepanel {

    &, &.medium {
      .ns-sidepanel__content {
        min-height: 0;
        max-height: 100vh;
      }
    }
  }
}

@media screen and (orientation: landscape) and (max-height: 500px) {
  .ns-layout.tablet {
    background-size: 100%;
    background-position: center bottom;
  }
}

@media screen and (orientation: landscape) and (max-height: 1024px) {
  .ns-sidepanel.medium {
    .ns-sidepanel__content {
      overflow: auto;
      height: $sidepanel-medium-center-min-height-xs;
      max-height: $sidepanel-medium-center-max-height-xs;
    }

    .ns-sidepanel__body {
      flex-grow: inherit;
    }
  }

  .ns-sidepanel.medium.profile-request {
    .ns-sidepanel__content {
      overflow: auto;
      height: auto;
      max-height: $sidepanel-medium-center-max-height-xs;
    }  
  }
}
