.ns-datepicker {

  input[type="date"]::-webkit-calendar-picker-indicator,
  input[type="time"]::-webkit-calendar-picker-indicator {
    display: none;
  }

  .ns-datepicker__inner {
    cursor: pointer;
    outline: 0;
  }

  .ns-datepicker__icon i {
    color: $gray3 !important;
    font-size: 1.2rem !important;
  }

  .ns-datepicker__inner.flatpickr-input {
    border-color: $gray4;
    height: x1-2($space);

    &:focus {
      outline: 0;
      box-shadow: $input-box-shadow-focus;
      opacity: 1;
    }
  }
}

.ns-datepicker.ns-datepicker--icon .ns-datepicker__content .ns-datepicker__icon {
  @include flex-end;
  pointer-events: none;
  width: 2rem;
}

.ns-field.focus .ns-datepicker .ns-datepicker__inner {
  border-color: $info;
  box-shadow: $input-box-shadow-focus;
}
