/*configuración*/
$notifications-width: 432px;
$notifications-padding: d2($space) d2($space) d2($space) d1-2($space);
$notifications-border-radius: 8px;
$notifications-border-radius-palm: 8px;
$notifications-top-position: $layout-header-height-xs + $space;
$notifications-top-position-md: $layout-header-height-md + $space;
$notifications-top-position-lg: $layout-header-height + $space;
// Layout de mapa
$notifications-top-position-map: $layout-header-height-xs + $layout-map-height-sm + $space;
$notifications-top-position-map-md: $layout-header-height-md + $layout-map-height-sm + $space;
$notifications-top-position-map-lg: $layout-header-height + $layout-map-height + $space;

/*colores por defecto*/
$notifications-neutral-background: $gray4;
$notifications-neutral-color: $gray1;
$notifications-neutral-border: $gray3;
/*colores información*/
$notifications-info-background: $info-light;
$notifications-info-color: $info-dark;
$notifications-info-border: $info;
/*colores éxito*/
$notifications-success-background: lighten($success-light, 10%);
$notifications-success-color: $success-dark;
$notifications-success-border: $success;
/*colores alerta*/
$notifications-warning-background: $warning-light;
$notifications-warning-color: $warning-dark;
$notifications-warning-border: $warning;
/*colores error*/
$notifications-danger-background: $danger-light;
$notifications-danger-color: $danger;
$notifications-danger-border: $danger;


@import 'node_modules/ns-notifications/src/scss/component';

// Notificaciones para mapas
.municipality-layout .ns-layout:not(.info) + .ns-notifications {
  top: calc(#{x2($space)} + #{d4($space)});
  width: 100%;
  right: d2($space);
  pointer-events: none;

  li {
    pointer-events: all;
  }
}

.municipality-layout .ns-layout.info + .ns-notifications {
  top: $notifications-top-position-map;
}

// Cuando se abre el layout, se le aplica un z-index menor para que salga por debajo
.ns-layout.open + .ns-notifications,
.ns-sidepanel--overlay-active .ns-notifications {
  z-index: +40;
}

.ns-notifications {
  width: calc(100% - #{$space});
  max-width: 100vw;
  right: $space;
  z-index: $z-index-4;
}

.ns-notifications__item {
  border-right: 1px solid;
  align-items: center;
  cursor: auto;

  & > div,
  &.ns-notifications--close .ns-notifications__item--close {
    @include flex-middle;
  }

  &.ns-notifications--close {
    .ns-notifications__item--close {
      padding-left: $space;
      color: $danger-dark;
    }

    .ns-notifications__item--text {
      color: $danger-dark;
    }
  }

  &.ns-notifications--success {
    .ns-notifications__item--close,
    .ns-notifications__item--text {
      color: $success-dark;
    }
  }

  .ns-notifications__item--icon {
    display: none;
  }
}

@include media(sm) {
  .ns-notifications {
    width: $notifications-width;
    min-width: $notifications-width;
  }

  // Notificaciones para mapas
  .municipality-layout .ns-layout:not(.info) + .ns-notifications {
    @include flex-center;
    top: unset;

    .ns-notifications__item {
      width: 100%;
    }
  }
}

@include media(md) {
  .ns-notifications {
    width: $notifications-width;
    top: $notifications-top-position-md;
  }

  // Notificaciones para mapas
  .municipality-layout .ns-layout:not(.info) + .ns-notifications {
    @include flex-center;
    width: calc(100% - #{$space});
    bottom: unset;
    top: $notifications-top-position-map-md;
    .ns-notifications__item {
      width: max-content;
    }

    .ns-notifications__item--close {
      i {
        font-size: 1rem;
      }
    }
  }

  .municipality-layout .ns-layout.info + .ns-notifications {
    top: $notifications-top-position-map-md;
  }
}

@include media(lg) {
  // Notificaciones para mapas
  .municipality-layout .ns-layout:not(.info) + .ns-notifications {
    top: $notifications-top-position-map-lg;
  }

  .municipality-layout .ns-layout.info + .ns-notifications {
    top: $notifications-top-position-map-lg;
  }

  .ns-notifications {
    width: $notifications-width;
    top: $notifications-top-position-lg;
  }
}
