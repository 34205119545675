//------------------------------ SPACING HELPER-------------------------------//

// Margin auto
.margin-auto {
  margin-left: auto;
  margin-right: auto;
}

// Space map
$spacing-map: (
  '0':      0,
  'd6':      d6($space),
  'd5':      d5($space),
  'd4':      d4($space),
  'd3':      d3($space),
  'd2':      d2($space),
  'd1-2':  d1-2($space),
  'x1-2':  x1-2($space),
  'x2':      x2($space),
  'x3':      x3($space),
  'x4':      x4($space),
  'x5':      x5($space),
  'x6':      x6($space)
) !default;

@each $prop, $abbrev in (margin: m, padding: p) {
  @each $name, $size in $spacing-map {

    .#{$abbrev}-#{$name} {
      #{$prop}: $size;
    }
    .#{$abbrev}t-#{$name} {
      #{$prop}-top: $size;
    }
    .#{$abbrev}l-#{$name} {
      #{$prop}-left: $size;
    }
    .#{$abbrev}r-#{$name} {
      #{$prop}-right: $size;
    }
    .#{$abbrev}b-#{$name} {
      #{$prop}-bottom: $size;
    }

    .#{$abbrev}y-#{$name} {
      #{$prop}-top: $size;
      #{$prop}-bottom: $size;
    }
    .#{$abbrev}x-#{$name} {
      #{$prop}-left: $size;
      #{$prop}-right: $size;
    }

    .#{$abbrev} { #{$prop}: $space; }
    .#{$abbrev}t { #{$prop}-top: $space; }
    .#{$abbrev}l { #{$prop}-left: $space; }
    .#{$abbrev}r { #{$prop}-right: $space; }
    .#{$abbrev}b { #{$prop}-bottom: $space; }

    .#{$abbrev}y {
      #{$prop}-top: $space;
      #{$prop}-bottom: $space;
    }

    .#{$abbrev}x {
      #{$prop}-left: $space;
      #{$prop}-right: $space;
    }
  }
}

@each $breakpoint, $size in $breakpoints {
  @each $prop, $abbrev in (margin: m, padding: p) {
    @each $name, $size in $spacing-map {
      @include media($breakpoint) {
        .#{$abbrev}-#{$name}-#{$breakpoint} {
          #{$prop}: $size;
        }
        .#{$abbrev}t-#{$name}-#{$breakpoint} {
          #{$prop}-top: $size;
        }
        .#{$abbrev}l-#{$name}-#{$breakpoint} {
          #{$prop}-left: $size;
        }
        .#{$abbrev}r-#{$name}-#{$breakpoint} {
          #{$prop}-right: $size;
        }
        .#{$abbrev}b-#{$name}-#{$breakpoint} {
          #{$prop}-bottom: $size;
        }
        .#{$abbrev}y-#{$name}-#{$breakpoint} {
          #{$prop}-top: $size;
          #{$prop}-bottom: $size;
        }
        .#{$abbrev}x-#{$name}-#{$breakpoint} {
          #{$prop}-left: $size;
          #{$prop}-right: $size;
        }
      }
    }
  }
}

@each $breakpoint, $size in $breakpoints  {
  @each $prop, $abbrev in (margin: m, padding: p) {
    @include media($breakpoint) {

      .#{$abbrev}-#{$breakpoint} { #{$prop}: $space; }
      .#{$abbrev}t-#{$breakpoint} { #{$prop}-top: $space; }
      .#{$abbrev}l-#{$breakpoint} { #{$prop}-left: $space; }
      .#{$abbrev}r-#{$breakpoint} { #{$prop}-right: $space; }
      .#{$abbrev}b-#{$breakpoint} { #{$prop}-bottom: $space; }

      .#{$abbrev}y-#{$breakpoint} {
        #{$prop}-top: $space;
        #{$prop}-bottom: $space;
      }

      .#{$abbrev}x-#{$breakpoint} {
        #{$prop}-left: $space;
        #{$prop}-right: $space;
      }
    }
  }
}
