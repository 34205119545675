.hidden-rd {
  display: none !important;
}

.font-weight-normal {
  font-weight: 400;
}

.link,
a.link {
  color: $link-color;
  &:hover {
    color: $link-color-hover;
  }
}

.cursor-default{
  cursor: default;
}

.pb-0 {
  padding-bottom: 0!important;
}

.fs-tick-icon {
  padding-right: d5($space);
  font-size: 1rem;
}
