//----------------------------- Panel Variables -----------------------------//

$panel-dropdown-shadow: 0 4px 8px transparentize($black, .92),
                        0 10px 20px -5px transparentize($black, .96);
$panel-modal-shadow: 0 6px 16px transparentize($black, .88),
                     0 20px 25px -5px transparentize($black, .97);


//-------- Mixin panel. @include panel($bg-color, $border, $border-radius, $box-shadow);
@mixin panel(
  $bg-color: $white,
  $border: 0,
  $border-radius: 8px,
  $box-shadow: 0 1px 2px transparentize($black, .92)
)
{
  background-color: $bg-color;
  border: $border;
  border-radius: $border-radius;
  box-shadow: $box-shadow;
}


//----------------------------- Panel component -----------------------------//
.panel {
  @include panel;
}

.panel-secondary {
  @include panel(
  $box-shadow: #{0 2px 4px transparentize($black, .92),
                 0 8px 10px -3px transparentize($black,.98)} );
}

.panel--blog {
  @include panel(
  $border-radius: 12px,
  $box-shadow: #{0 10px 20px -5px transparentize($black, .92),
                 0 4px 8px 0 transparentize($black,.98)} );
  position: relative;
  margin-bottom: $space;
  padding: x1-2($space) d1-2($space);

  h3 {
    margin-bottom: d2($space);
    font-weight: 500;
  }
  img {
    margin: d1-2($space) 0;
  }
  .panel--blog__date {
    color: $black;
  }
  p a {
    color: $info;
    font-weight: $bold;
    &:hover {
      color: $info-dark;
    }
  }
  p + p {
    margin-top: $space;
  }
  .blog-url-button {
    position: absolute;
    top: d3($space);
    right: d1-2($space);
  }
}

.panel-schedules {
  padding: d1-2($space) d2($space);
  background-color: $gray5;
  border-radius: 8px;
}

// -------------------------------- Responsive -------------------------------//

@include media(sm) {
  .panel--blog {
    padding: x2($space);

    .blog-url-button {
      position: absolute;
      top: d1-2($space);
      right: d1-2($space);
    }
  }
}

@include media(md) {
  .panel--blog {
    padding: x3($space);
  }
}

@media (min-width: $sm) {
  .panel--blog {
    h3 {
      margin-bottom: d1-2($space);
    }
    img {
      margin: x2($space) 0;
    }
  }
}