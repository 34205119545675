.h100 {
  height: 100%;
}

.p-0 {
  padding: 0!important;
}

.img-small {
  width: x2($space) !important;
  height: x2($space) !important;
}

.img-medium {
  width: x3($space) !important;
  height: x3($space) !important;
}

.img-large {
  width: x4($space) !important;
  height: x4($space) !important;
}

.text-left-sm {
  @media screen and (min-width: $sm) {
    text-align: left;
  }
}

.line-through {
  text-decoration: line-through;
}

.text-gray2-hover {
  color: $gray2;

  &:hover {
    color: $black;
  }
}

.text-black-hover {
  color: red;

  &:hover {
    color: $gray2;
  }
}

.fs-theta {
  line-height: 1;
}

.radius-4 {
  border-radius: 4px;
}

.radius-6 {
  border-radius: 6px;
}

.no-events {
  pointer-events: none !important;
}

.nowrap-sm {
  @media (min-width: $sm) {
    white-space: nowrap;
  }
}

.w0 {
  min-width: 0; //for text-ellipsis using flex
}

.pac-container:empty {
  display: none;
}

.pointer-auto {
  pointer-events: auto;
}

.pd-top-1-5{
  padding-top: 1.5rem;
}

a.pointer:focus {
  color: #1c507d;
  text-decoration: none;
}

.error {
  color: red;
  font-size: $font-size-x-small;
  margin-top: d3($space);
}