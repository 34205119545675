html {
  background-color: $gray5;
}

[class*="ns-icon-"] {
  font-size: 1.5rem;
}

@media screen and (max-width: $md) {
  * {
    -webkit-tap-highlight-color: rgba(0,0,0,0);
  }
}

hr {
  padding: 0;
  background: $gray4;
  height: 1px;
}
