$vh: var(--vh);

@media screen and (max-width: $md) {
  html.device-fixed {
    position: fixed;

    .ns-layout {
      width: 100vw;
      min-height: calc(#{$vh} * 100 - #{$layout-header-height-xs});

      &.tablet {
        .ns-layout-sidebar {
          margin-top: #{$layout-header-height-xs} !important;
        }
      }
    }

    .ns-layout {
      min-height: calc(#{$vh} * 100 - #{$layout-header-height-xs});
    }

    .municipality-layout .ns-layout {
      min-height: calc(#{$vh} * 100);
    }

    .ns-layout__sidebar,
    .ns-layout__sidebar > div  {
      min-height: calc(#{$vh} * 100 - #{$layout-header-height-xs} ) !important;
    }

    .ns-layout__sidebar  {
      max-height: calc(#{$vh} * 100 - #{$layout-header-height-xs}) !important;
    }

    .ns-layout__sidebar__map__menu + .relative .component-ns-leaflet {
      height: calc(#{$vh} * 100 - #{$layout-header-height-xs} - #{$layout-map-height-xs});

      @media screen and (min-width: $xs) {
        height: calc(#{$vh} * 100 - #{$layout-header-height-xs} - #{$layout-map-height-sm});
      }
    }

    .ns-layout__content {
      main:not(.ns-layout__content__map) {
        min-height: calc(#{$vh} * 100 - #{$layout-header-height-xs});
      }
    }

    .ns-layout__content__map {
      .ns-layout__content__compacted {
        top: 0;
      }
    }

    .sidepanel-map {
      transform: translateY(calc(#{$vh} * 100 - #{$layout-header-height-xs} - #{$layout-map-height-xs} - #{x2($space)} + -25px));
      &.is-open {
        -webkit-transform: none;
        transform: none;
        height: calc(100vh - 6rem);
        top: 9rem;
      }

      .sidepanel-map__container {
        padding-bottom: x2($space);
      }
    }
  }

  html:not(.device-fixed) .ns-layout {
    min-height: calc(#{$vh} * 100);
  }
}

@media screen and (min-width: $sm) and (max-width: $md) {
  html.device-fixed {
    .sidepanel-map {
      transform: translateY(calc(#{$vh} * 100 - #{$layout-header-height-xs} - #{$layout-map-height-sm} - #{x6($space)} + 5px));
    }
  }
}

//Landscape
@media screen and (orientation: landscape) and (max-height: 450px) {
  .ns-layout.hover .ns-layout__sidebar {
    min-height: calc(#{$vh} * 100 - #{$layout-header-height-xs});
  }
}
