//------------------------------ FLEXBOX HELPER ------------------------------//
.flex {
  @include flex;
}

.inline-flex {
  @include inline-flex;
}

.start {
  @include start;
}

.center {
  @include center;
}

.end {
  @include end;
}

.top {
  @include top;
}

.middle {
  @include middle;
}

.bottom {
  @include bottom;
}

.between {
  @include between;
}

.around {
  @include around;
}

.column {
  @include column;
}

.column-reverse {
  @include column-reverse;
}

.flex-row {
  @include flex-row;
}

.bullseye {
  @include bullseye;
}

.first {
  @include first;
}

.last {
  @include last;
}

@each $breakpoint, $size in $breakpoints {
  @include media($breakpoint) {
    .flex-#{$breakpoint} {
      @include flex;
    }

    .inline-flex-#{$breakpoint} {
      @include inline-flex;
    }

    .start-#{$breakpoint} {
      @include start;
    }

    .center-#{$breakpoint} {
      @include center;
    }

    .end-#{$breakpoint} {
      @include end;
    }

    .top-#{$breakpoint} {
      @include top;
    }

    .middle-#{$breakpoint} {
      @include middle;
    }

    .bottom-#{$breakpoint} {
      @include bottom;
    }

    .between-#{$breakpoint} {
      @include between;
    }

    .around-#{$breakpoint} {
      @include around;
    }

    .column-#{$breakpoint} {
      @include column;
    }

    .column-reverse-#{$breakpoint} {
      @include column-reverse;
    }

    .flex-row-#{$breakpoint} {
      @include flex-row;
    }

    .bullseye-#{$breakpoint} {
      @include bullseye;
    }

    .first-#{$breakpoint} {
      @include first;
    }

    .last-#{$breakpoint} {
      @include last;
    }
  }
}


.flex-wrap {
  @include flex-wrap;
}

.stretch-xs {
  align-items: stretch;
}

.self-start {
  align-self: flex-start;
}

.self-end {
  align-self: flex-end;
}

.self-start-xs {
  align-self: flex-start;
}

.grow-1 {
  flex-grow: 1;
}

.column-xs {
  @include media(xs) {
    display: flex;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}


@media screen and (min-width: $sm) {
  .self-start-xs {
    align-self: inherit;
  }
}
