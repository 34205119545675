$field-bg-color:                   $white;
$field-font-color:                 $gray1;
$field-font-size:                  $font-size-small;
$field-placeholder-color:          $gray3;
$field-border-color:               $gray4;
$field-border-color-hover:         $gray4;
$field-border-color-focus:         $primary;
$field-border-color-active:        $primary;
$field-bg-color-disabled:          $gray5;
$field-font-color-disabled:        $gray3;
$field-placeholder-color-disabled: $gray3;
$field-border-color-disabled:      #ced4da;
$field-icon-color:                 $gray3;
$field-icon-color-disabled:        $gray4;
$field-color-info:                 $info;
$field-color-success:              $success;
$field-color-warning:              $warning;
$field-color-danger:               $danger;
$field-border-radius:              4px;
$field-height:                     x1-2($space);
$field-padding-x:                  d1-2($space);

$field-transition: border 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);

$field-small-font-size: $font-size-x-small;
$field-small-height:    $space;
$field-small-padding-x: d2($space);

$field-large-font-size: 1.2rem;
$field-large-height:    48px;

$field-bg-color-prepend:   $gray5;
$field-font-color-prepend: $gray2;

@import 'node_modules/ns-field/src/scss/component';

.ns-field {
  .ns-field__label label {
    color: $gray2;
    padding-bottom: d6($space);
    font-size: $font-size-small;
  }

  &.normal-label label {
    color: $black;
    font-size: $font-size;
  }
}

.ns-field--help .ns-field__label {
  label {
    padding-bottom: 0;
  }
}

.ns-field.error {
  .ns-field__content .ns-form__text-error {
    font-size: $font-size-x-small;
    margin-top: d3($space);
  }

  .ns-input input:focus,
  .ns-textarea textarea:focus,
  .ns-datepicker__inner.flatpickr-input:focus {
    box-shadow: none;
  }
}


.ns-field--mob-passenger {
  .ns-field__label {
    label {
      color: $black;
      font-size: d1-2($space);
    }
  }
}
