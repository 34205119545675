$loading-mask-background: rgba($white, 0.6) !default;
$loading-icon-color: $secondary;
$loading-font-color: $secondary;
$loading-transition-duration: 0.25s;

@import 'node_modules/ns-loading/src/scss/component';

.ns-loading {
  .ns-loading__content__spinner {
    display: none;
  }
  .loader-bg {
    border-radius: 50%;
    background-image: url("assets/images/page/loader-bg.svg");
    width: 150px;
    min-width: 150px;
    height: 150px;
    min-height: 150px;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 90px;
      height: 90px;
      animation: imgRotate 2s infinite;
    }
  }

  .ns-loading__content__text {
    margin-top: 1.25rem;
    font-size: 1.2rem;
    color: $primary;
  }

  .ns-loading__content__slot {
    display: flex;
    justify-content: center;
  }
}

.transition-loading-enter-active,
.transition-loading-leave-active {
  transition: opacity 0s;
}

.transition-loading-enter,
.transition-loading-leave-to {
  transition: opacity $loading-transition-duration;
  opacity: 0;
}



@keyframes imgRotate {
  0% {
    transform: rotate(0deg);
  }

  50% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
